import React from 'react';
import { TextInput, View, StyleSheet, Platform, TouchableOpacity, Text, KeyboardAvoidingView } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { useFonts } from 'expo-font';


import colors from '../colors/colors';

function AppTextInput({ icon, placeHolderName, onChangeEvent, sizeProp, text, style, onPressProp, showSend, lottie, lottieStyle, referance, color, textInputStyle }) {

    const [fontsLoaded] = useFonts({
        'AppFont': require("../fonts/WebsiteFont.ttf"),
    });


    return (
        <TouchableOpacity style={[{
            width: sizeProp ? sizeProp + '%' : 300,
            padding: 15,
            borderRadius: 30,
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            height: 60,

        }, style]} onPress={onPressProp}>

            <LinearGradient
                colors={['#FF5733', '#A83279']}
                style={{ height: '100%', width: '100%', position: 'absolute', justifyContent: 'center', alignItems: 'center', }}
            >

            </LinearGradient>

            <TextInput style={[styles.textStyle, textInputStyle]} placeholder={placeHolderName} onChangeText={onChangeEvent} ref={referance}>




            </TextInput>


        </TouchableOpacity>
    );
}
const styles = StyleSheet.create({

    textStyle: {

        fontSize: 16,
        fontFamily: 'AppFont',
        fontWeight: 'bold',
        textAlign: 'center',
        zIndex: 1,
        color: colors.white
    },

})

export default AppTextInput;