import React from 'react';
import { View, StyleSheet, Text, Masked, Animated } from 'react-native';
import colors from '../colors/colors';
import { useFonts } from 'expo-font';
import { LinearGradient } from 'react-text-gradients'


function AppText({ style, text, lines, gradient, gradientSize, left, right }) {

    const [fontsLoaded] = useFonts({
        'AppFont': require("../fonts/WebsiteFont.ttf"),
    });

    return (
        <>
            {!gradient && <Animated.Text style={[styles.textStyle, style]} numberOfLines={lines}>{text}</Animated.Text>}

            {gradient && <LinearGradient gradient={['to left', '#FF5733 ,#A83279']} style={{
                fontSize: gradient ? gradientSize : 16,

                fontWeight: '410',
                textAlign: left ? (!right ? 'left' : 'right') : 'center',
                fontFamily: 'AppFont',
                width: '100%'
            }}>{text}</LinearGradient>}
        </>
    );
}
const styles = StyleSheet.create({
    textStyle: {

        fontSize: 16,

        fontWeight: '410',
        textAlign: 'center',
        fontFamily: 'AppFont'
    },
})
export default AppText;