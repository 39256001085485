import React, { useEffect, useRef, useState } from 'react';
import { View, ScrollView, Animated, Image, TouchableOpacity, Linking } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';

import colors from '../colors/colors';
import AppText from '../components/AppText';

const height = window.innerHeight;
const width = window.innerWidth;


function MainScreen({ navigation }) {

    const scrollViewRef = useRef(null);

    const [scrollPosition, setScrollPosition] = useState(0);

    const AnfieldTextOpacity = useState(new Animated.Value(0))[0];

    const FansTextOpacity = useState(new Animated.Value(0))[0];

    const startScrollingOpacity = useState(new Animated.Value(0))[0];

    const toogleOpacities = () => {

        Animated.timing(AnfieldTextOpacity, {
            toValue: 1,
            duration: 800,
            useNativeDriver: true,
        }).start();

        setTimeout(function () {
            Animated.timing(FansTextOpacity, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 500)

        setTimeout(function () {
            Animated.timing(startScrollingOpacity, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 800)



    };

    useEffect(() => {

        setTimeout(function () { toogleOpacities() }, 500)

    }, [])


    const updateScrollPosition = (y) => {


        if ((height < 550 && width < 650) || (height < 800 && width > 650))
            setScrollPosition(7000)
        else {

            setScrollPosition(y)


        }

    }

    return (

        <ScrollView onScroll={(event) => {

            updateScrollPosition(event.nativeEvent.contentOffset.y)
        }} ref={scrollViewRef} scrollEventThrottle={1} style={{ backgroundColor: colors.black }}>

            <View style={{ width: width, height: height, alignItems: 'center' }}>



                {width > 650 && <View style={{ width: width, height: height, alignItems: 'center', paddingTop: 50 }}>


                    <Animated.View style={{ width: '80%', height: width / 7, alignItems: 'flex-start', opacity: AnfieldTextOpacity }}>
                        <AppText text={"ANFIELD"} gradientSize={width / 7} gradient={true} />
                    </Animated.View>


                    <Animated.View style={{ width: '80%', height: width / 7, alignItems: 'flex-start', opacity: FansTextOpacity }}>
                        <AppText text={"FANS"} gradientSize={width / 7} gradient={true} />
                    </Animated.View>




                    <Animated.View style={{ width: 180, height: 45, opacity: scrollPosition <= 0 ? startScrollingOpacity : 1 - (scrollPosition / 100), justifyContent: 'center', alignItems: 'center', overflow: 'hidden', borderRadius: 30, position: 'absolute', bottom: 30 }}>
                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', }}
                        >

                            <AppText text={"START SCROLLING"} style={{ fontSize: 16, color: colors.white }} />

                        </LinearGradient>

                    </Animated.View>


                </View>}


                {width < 650 && <View style={{ width: width, alignItems: 'center', justifyContent: 'center', height: height }}>


                    <Animated.View style={{ width: '90%', alignItems: 'flex-start', opacity: AnfieldTextOpacity }}>
                        <AppText text={"ANFIELD"} gradientSize={100} gradient={true} />
                    </Animated.View>


                    <Animated.View style={{ width: '90%', alignItems: 'flex-start', opacity: FansTextOpacity }}>
                        <AppText text={"FANS"} gradientSize={100} gradient={true} />
                    </Animated.View>




                    <Animated.View style={{ width: 180, height: 45, opacity: scrollPosition <= 0 ? startScrollingOpacity : 1 - (scrollPosition / 100), justifyContent: 'center', alignItems: 'center', overflow: 'hidden', borderRadius: 30, position: 'absolute', bottom: 30 }}>
                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: '100%', width: '100%', justifyContent: 'center', alignItems: 'center', }}
                        >

                            <AppText text={"START SCROLLING"} style={{ fontSize: 16, color: colors.white }} />

                        </LinearGradient>

                    </Animated.View>


                </View>}

                {width > 650 && <View style={{ width: width, height: height, flexDirection: 'row' }}>

                    <View style={{ width: '50%', height: '100%', alignItems: 'center', backgroundColor: colors.black, overflow: 'visible', zIndex: 1 }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -2.8 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"Songs"} gradientSize={width / 8} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Listen to the best Liverpool FC football chants."} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '76%', opacity: -3.5 + (scrollPosition / 100) }} />


                        <Animated.View style={{ width: '79%', height: 50, marginVertical: 30, alignItems: 'flex-start', opacity: -4 + (scrollPosition / 100) }}>

                            <TouchableOpacity onPress={() => navigation.navigate("SongsScreen")}>

                                <LinearGradient
                                    colors={['#FF5733', '#A83279']}
                                    style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                                >

                                    <AppText text={"Listen"} style={{ fontSize: 16, color: colors.white }} />

                                </LinearGradient>

                            </TouchableOpacity>

                        </Animated.View>

                    </View>


                    <View style={{ width: '50%', height: '100%', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', backgroundColor: colors.black, overflow: 'visible', zIndex: 1 }}>

                        <Animated.Image style={{
                            width: 300, height: 420, position: 'absolute', right: 80, top: 200, opacity: (scrollPosition / 100) < 5.9 ? -4.8 + (scrollPosition / 100) : 7.3 - (scrollPosition / 100), borderRadius: 30, shadowColor: colors.primary,
                            shadowOffset: {
                                width: 0,
                                height: 20,
                            },
                            shadowOpacity: 1,
                            shadowRadius: 50,
                        }} source={{ uri: "https://www.thisisanfield.com/wp-content/uploads/100206-008-Liverpool_Everton.jpg", }} />

                        <Animated.View style={{ width: 350, height: 500, opacity: -3.8 + (scrollPosition / 100), zIndex: 1, alignItems: 'center', borderRadius: 30, transform: (scrollPosition / 100) > 6.5 ? [{ rotateY: (scrollPosition < 850 ? (scrollPosition / 5) - 130 + 'deg' : (850 / 5) - 130 + 'deg') }, { rotateX: (scrollPosition / 10) - 65 + 'deg' }] : [] }}>

                            <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), marginTop: 30, zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`WE'VE `} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -6.2 + (scrollPosition / 100) }} />

                                <AppText text={`CONQUERED`} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -6.3 + (scrollPosition / 100) }} />
                            </Animated.View>

                            <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), marginTop: 5, zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`ALL `} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -6.4 + (scrollPosition / 100) }} />

                                <AppText text={`OF `} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -6.55 + (scrollPosition / 100) }} />

                                <AppText text={`EUROPE`} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -6.65 + (scrollPosition / 100) }} />
                            </Animated.View>

                            <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), marginTop: 5, zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`WE'RE `} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -6.75 + (scrollPosition / 100) }} />

                                <AppText text={`NEVER `} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -6.85 + (scrollPosition / 100) }} />

                                <AppText text={`GOING`} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -6.95 + (scrollPosition / 100) }} />
                            </Animated.View>

                            <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), marginTop: 5, zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`TO `} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -7 + (scrollPosition / 100) }} />

                                <AppText text={`STOP... `} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -7.1 + (scrollPosition / 100) }} />

                            </Animated.View>

                            <Animated.Image style={{ width: 350, height: 500, position: 'absolute', opacity: -3.8 + (scrollPosition / 100), borderRadius: 30 }} source={{ uri: "https://www.si.com/.image/t_share/MTY4MDE3MTQ3NjIwMzY5Njgx/liverpool-v-barcelona-uefa-champions-league-semi-final-second-leg-5cd295ef40dda37baa000001jpg.jpg", }} />
                        </Animated.View>

                        <Animated.Image style={{ width: 300, height: 420, position: 'absolute', top: 200, left: 80, opacity: (scrollPosition / 100) < 5.9 ? -4.3 + (scrollPosition / 100) : 7.3 - (scrollPosition / 100), borderRadius: 30 }} source={{ uri: "https://www.theanfieldwrap.com/uploads/2019/06/20190601-031-UEFA_Champions_League_Final.jpg", }} />

                    </View>


                </View>}

                {width < 650 && <View style={{ width: width, height: height }}>

                    <View style={{ width: '100%', height: '40%', alignItems: 'center', overflow: 'visible', zIndex: 1 }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -2.8 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"Songs"} gradientSize={width / 8} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Listen to the best Liverpool FC football chants."} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '76%', opacity: -3.5 + (scrollPosition / 100) }} />


                        <Animated.View style={{ width: '79%', height: 50, marginVertical: 30, alignItems: 'flex-start', opacity: -4 + (scrollPosition / 100) }}>

                            <TouchableOpacity onPress={() => navigation.navigate("SongsScreen")}>

                                <LinearGradient
                                    colors={['#FF5733', '#A83279']}
                                    style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                                >

                                    <AppText text={"Listen"} style={{ fontSize: 16, color: colors.white }} />

                                </LinearGradient>

                            </TouchableOpacity>

                        </Animated.View>

                    </View>


                    <View style={{ width: '100%', height: '50%', justifyContent: 'center', flexDirection: 'row', zIndex: 1 }}>

                        <Animated.Image style={{
                            width: 100, height: 230, position: 'absolute', right: 60, top: 30, opacity: (scrollPosition / 100) < 5.9 ? -4.8 + (scrollPosition / 100) : 6.5 - (scrollPosition / 100), borderRadius: 30, shadowColor: colors.primary,
                            shadowOffset: {
                                width: 0,
                                height: 20,
                            },
                            shadowOpacity: 1,
                            shadowRadius: 50,
                        }} source={{ uri: "https://www.thisisanfield.com/wp-content/uploads/100206-008-Liverpool_Everton.jpg", }} />

                        <Animated.View style={{ width: 180, height: 300, opacity: -3.8 + (scrollPosition / 100), zIndex: 1, alignItems: 'center', borderRadius: 30, transform: (scrollPosition / 100) > 6.5 ? [{ rotateY: (scrollPosition < 850 ? (scrollPosition / 5) - 130 + 'deg' : (850 / 5) - 130 + 'deg') }, { rotateX: (scrollPosition / 10) - 65 + 'deg' }] : [] }}>

                            <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), marginTop: 30, zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`WE'VE `} style={{ fontSize: 13, color: colors.white, textAlign: 'left', opacity: -6.2 + (scrollPosition / 100) }} />

                                <AppText text={`CONQUERED`} style={{ fontSize: 13, color: colors.white, textAlign: 'left', opacity: -6.3 + (scrollPosition / 100) }} />
                            </Animated.View>

                            <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), marginTop: 5, zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`ALL `} style={{ fontSize: 13, color: colors.white, textAlign: 'left', opacity: -6.4 + (scrollPosition / 100) }} />

                                <AppText text={`OF `} style={{ fontSize: 13, color: colors.white, textAlign: 'left', opacity: -6.55 + (scrollPosition / 100) }} />

                                <AppText text={`EUROPE`} style={{ fontSize: 13, color: colors.white, textAlign: 'left', opacity: -6.65 + (scrollPosition / 100) }} />
                            </Animated.View>

                            <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), marginTop: 5, zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`WE'RE `} style={{ fontSize: 13, color: colors.white, textAlign: 'left', opacity: -6.75 + (scrollPosition / 100) }} />

                                <AppText text={`NEVER `} style={{ fontSize: 13, color: colors.white, textAlign: 'left', opacity: -6.85 + (scrollPosition / 100) }} />

                                <AppText text={`GOING`} style={{ fontSize: 13, color: colors.white, textAlign: 'left', opacity: -6.95 + (scrollPosition / 100) }} />
                            </Animated.View>

                            <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), marginTop: 5, zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`TO `} style={{ fontSize: 13, color: colors.white, textAlign: 'left', opacity: -7 + (scrollPosition / 100) }} />

                                <AppText text={`STOP... `} style={{ fontSize: 13, color: colors.white, textAlign: 'left', opacity: -7.1 + (scrollPosition / 100) }} />

                            </Animated.View>

                            <Animated.Image style={{ width: 180, height: 300, position: 'absolute', opacity: -3.8 + (scrollPosition / 100), borderRadius: 30 }} source={{ uri: "https://www.si.com/.image/t_share/MTY4MDE3MTQ3NjIwMzY5Njgx/liverpool-v-barcelona-uefa-champions-league-semi-final-second-leg-5cd295ef40dda37baa000001jpg.jpg", }} />
                        </Animated.View>

                        <Animated.Image style={{ width: 100, height: 230, position: 'absolute', top: 30, left: 60, opacity: (scrollPosition / 100) < 5.9 ? -4.3 + (scrollPosition / 100) : 6.5 - (scrollPosition / 100), borderRadius: 30 }} source={{ uri: "https://www.theanfieldwrap.com/uploads/2019/06/20190601-031-UEFA_Champions_League_Final.jpg", }} />

                    </View>


                </View>}

                {width > 650 && <Animated.View style={{ width: width, height: height, flexDirection: 'row', opacity: -11 + (scrollPosition / 100), zIndex: 1 }}>

                    <LinearGradient
                        colors={['#000', '#f20c0c']}
                        style={{ height: '100%', width: '100%', position: 'absolute', transform: [{ rotate: "0deg" }] }}
                    >


                    </LinearGradient>



                    <View style={{ width: '50%', height: '100%', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', overflow: 'visible' }}>



                        <Animated.View style={{ width: 300, height: 500, opacity: -14.5 + (scrollPosition / 100), overflow: 'visible' }}>


                            <Animated.View style={{ width: 900, height: 500, flexDirection: 'row' }}>

                                <Animated.View style={{ width: 300, height: 500, borderRadius: 30, alignItems: 'center', transform: [{ translateX: scrollPosition < 1820 ? ((scrollPosition / 100) > 15.5 ? (-scrollPosition * 1.4) + (1550 * 1.4) : 0) : 0 }], opacity: +16.5 - (scrollPosition / 100), overflow: 'hidden' }}>

                                    <Image style={{ width: '100%', height: '100%', borderRadius: 30, position: 'absolute' }} source={{ uri: "https://akm-img-a-in.tosshub.com/indiatoday/images/story/202303/ap23064660165793-three_four.jpg?VersionId=uM_eO7NpdEiOOGl7UsyNEGrG6vSY5x6q" }} />

                                    <Animated.View style={{ width: '80%', alignItems: 'flex-start', marginTop: 5, zIndex: 1 }}>
                                        <AppText text={`Top`} style={{ fontSize: 30, color: colors.white, textAlign: 'left' }} />
                                    </Animated.View>

                                    <Animated.View style={{ width: '80%', alignItems: 'flex-start', zIndex: 1 }}>
                                        <AppText text={`Goalscorer`} style={{ fontSize: 30, color: colors.white, textAlign: 'left' }} />
                                    </Animated.View>


                                </Animated.View>

                                <Animated.View style={{ width: 300, height: 500, borderRadius: 30, alignItems: 'center', transform: [{ translateX: scrollPosition < 1950 ? ((scrollPosition / 100) > 16 ? (-scrollPosition * 1.4) + (1600 * 1.4) : 0) : 0 }], opacity: scrollPosition < 1750 ? -16 + (scrollPosition / 100) : 18.5 - (scrollPosition / 100), overflow: 'hidden' }}>

                                    <Image style={{ width: '100%', height: '100%', borderRadius: 30, position: 'absolute' }} source={{ uri: "https://prod-printler-front-as.azurewebsites.net/media/photo/125291.jpg?mode=crop&width=727&height=1024&rnd=0.0.1" }} />

                                    <Animated.View style={{ width: '80%', alignItems: 'flex-start', marginTop: 5, zIndex: 1 }}>
                                        <AppText text={`Most`} style={{ fontSize: 30, color: colors.white, textAlign: 'left' }} />
                                    </Animated.View>

                                    <Animated.View style={{ width: '80%', alignItems: 'flex-start', zIndex: 1 }}>
                                        <AppText text={`Assists`} style={{ fontSize: 30, color: colors.white, textAlign: 'left' }} />
                                    </Animated.View>

                                </Animated.View>

                                <Animated.View style={{ width: 300, height: 500, borderRadius: 30, alignItems: 'center', transform: [{ translateX: scrollPosition < 2080 ? ((scrollPosition / 100) > 16.5 ? (-scrollPosition * 1.4) + (1650 * 1.4) : 0) : 0 }], opacity: scrollPosition < 1880 ? -18 + (scrollPosition / 100) : 20.5 - (scrollPosition / 100), overflow: 'hidden' }}>

                                    <Image style={{ width: '100%', height: '100%', borderRadius: 30, position: 'absolute' }} source={{ uri: "https://pbs.twimg.com/media/F-WXAyvXUAA5rAF.jpg:large" }} />

                                    <Animated.View style={{ width: '80%', alignItems: 'flex-start', marginTop: 5, zIndex: 1 }}>
                                        <AppText text={`League`} style={{ fontSize: 30, color: colors.white, textAlign: 'left' }} />
                                    </Animated.View>

                                    <Animated.View style={{ width: '80%', alignItems: 'flex-start', zIndex: 1 }}>
                                        <AppText text={`Position`} style={{ fontSize: 30, color: colors.white, textAlign: 'left' }} />
                                    </Animated.View>

                                </Animated.View>

                            </Animated.View>



                        </Animated.View>

                    </View>

                    <View style={{ width: '50%', height: '100%', alignItems: 'center', overflow: 'visible' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -12 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"STATS"} gradientSize={width / 8} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Check All of the Liverpool FC Statistics here"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '76%', opacity: -13 + (scrollPosition / 100) }} />


                        <Animated.View style={{ width: '79%', height: 50, marginVertical: 30, alignItems: 'flex-start', opacity: -14 + (scrollPosition / 100) }}>

                            <TouchableOpacity onPress={() => navigation.navigate("StatsScreen")}>

                                <LinearGradient
                                    colors={['#FF5733', '#A83279']}
                                    style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                                >

                                    <AppText text={"See"} style={{ fontSize: 16, color: colors.white }} />

                                </LinearGradient>

                            </TouchableOpacity>

                        </Animated.View>

                    </View>


                </Animated.View>}

                {width < 650 && <Animated.View style={{ width: width, height: height, opacity: -9 + (scrollPosition / 100), zIndex: 1 }}>






                    <View style={{ width: '100%', height: '50%', alignItems: 'center', overflow: 'visible' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -10 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"STATS"} gradientSize={width / 8} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Check All of the Liverpool FC Statistics here"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '76%', opacity: -11 + (scrollPosition / 100) }} />


                        <Animated.View style={{ width: '79%', height: 50, marginVertical: 30, alignItems: 'flex-start', opacity: -12 + (scrollPosition / 100) }}>

                            <TouchableOpacity onPress={() => navigation.navigate("StatsScreen")}>

                                <LinearGradient
                                    colors={['#FF5733', '#A83279']}
                                    style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                                >

                                    <AppText text={"See"} style={{ fontSize: 16, color: colors.white }} />

                                </LinearGradient>

                            </TouchableOpacity>

                        </Animated.View>

                    </View>

                    <View style={{ width: '100%', height: '50%', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', overflow: 'visible' }}>



                        <Animated.View style={{ width: '70%', height: '90%', opacity: -12.5 + (scrollPosition / 100), overflow: 'visible' }}>


                            <Animated.View style={{ width: '100%', height: '100%', flexDirection: 'row' }}>

                                <Animated.View style={{ width: '100%', height: '100%', borderRadius: 30, alignItems: 'center', transform: [{ rotateY: scrollPosition < 1620 ? ((scrollPosition / 100) > 13 ? (-scrollPosition) + (1300) + "deg" : 0 + "deg") : 0 + "deg" }], opacity: +14 - (scrollPosition / 100), overflow: 'hidden' }}>

                                    <Image style={{ width: '100%', height: '100%', borderRadius: 30, position: 'absolute' }} source={{ uri: "https://akm-img-a-in.tosshub.com/indiatoday/images/story/202303/ap23064660165793-three_four.jpg?VersionId=uM_eO7NpdEiOOGl7UsyNEGrG6vSY5x6q" }} />

                                    <Animated.View style={{ width: '80%', alignItems: 'flex-start', marginTop: 5, zIndex: 1 }}>
                                        <AppText text={`Top`} style={{ fontSize: 30, color: colors.white, textAlign: 'left' }} />
                                    </Animated.View>

                                    <Animated.View style={{ width: '80%', alignItems: 'flex-start', zIndex: 1 }}>
                                        <AppText text={`Goalscorer`} style={{ fontSize: 30, color: colors.white, textAlign: 'left' }} />
                                    </Animated.View>


                                </Animated.View>

                                <Animated.View style={{ width: '100%', height: '100%', borderRadius: 30, position: 'absolute', alignItems: 'center', transform: [{ rotateY: scrollPosition < 1750 ? ((scrollPosition / 100) > 13.25 ? (-scrollPosition) + (1325) + "deg" : 0 + "deg") : 0 + "deg" }], opacity: scrollPosition < 1450 ? -14 + (scrollPosition / 100) : 17 - (scrollPosition / 100), overflow: 'hidden' }}>

                                    <Image style={{ width: '100%', height: '100%', borderRadius: 30, position: 'absolute' }} source={{ uri: "https://prod-printler-front-as.azurewebsites.net/media/photo/125291.jpg?mode=crop&width=727&height=1024&rnd=0.0.1" }} />

                                    <Animated.View style={{ width: '80%', alignItems: 'flex-start', marginTop: 5, zIndex: 1 }}>
                                        <AppText text={`Most`} style={{ fontSize: 30, color: colors.white, textAlign: 'left' }} />
                                    </Animated.View>

                                    <Animated.View style={{ width: '80%', alignItems: 'flex-start', zIndex: 1 }}>
                                        <AppText text={`Assists`} style={{ fontSize: 30, color: colors.white, textAlign: 'left' }} />
                                    </Animated.View>

                                </Animated.View>


                            </Animated.View>



                        </Animated.View>

                    </View>

                </Animated.View>}

                {width > 650 && <Animated.View style={{ width: width, height: height, flexDirection: 'row', opacity: -17 + (scrollPosition / 100), zIndex: 1 }}>

                    <LinearGradient
                        colors={['#000', '#f20c0c']}
                        style={{ height: '100%', width: '100%', position: 'absolute', transform: [{ rotate: "180deg" }] }}
                    >


                    </LinearGradient>

                    <View style={{ width: '50%', height: '100%', alignItems: 'center', overflow: 'visible' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -19.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"QUIZES"} gradientSize={width / 8} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Test your Liverpool FC knowladge and play some quizes"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '76%', opacity: -21.5 + (scrollPosition / 100) }} />


                        <Animated.View style={{ width: '79%', height: 50, marginVertical: 30, alignItems: 'flex-start', opacity: -23 + (scrollPosition / 100) }}>

                            <TouchableOpacity onPress={() => { navigation.navigate("QuizScreen") }}>

                                <LinearGradient
                                    colors={['#FF5733', '#A83279']}
                                    style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                                >

                                    <AppText text={"Quiz"} style={{ fontSize: 16, color: colors.white }} />

                                </LinearGradient>

                            </TouchableOpacity>

                        </Animated.View>

                    </View>


                    <View style={{ width: '50%', height: '80%', justifyContent: 'center', alignItems: 'center', overflow: 'visible', marginTop: 60 }}>


                        <AppText text={"Who is the all time best goal scorer for Liverpool FC?"} style={{ fontSize: 30, color: colors.white, textAlign: 'left', width: '76%', opacity: -24.5 + (scrollPosition / 100) }} />
                        <Animated.View style={{ width: '76%', alignItems: 'center', opacity: -25 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"Mo Salah"} gradientSize={70} gradient={true} left={true} />
                        </Animated.View>

                    </View>




                </Animated.View>}

                {width < 650 && <Animated.View style={{ width: width, height: height, opacity: -12 + (scrollPosition / 100), zIndex: 1 }}>



                    <View style={{ width: '100%', height: '50%', alignItems: 'center', overflow: 'visible' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -13.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"QUIZES"} gradientSize={80} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Test your Liverpool FC knowladge and play some quizes"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '76%', opacity: -15.5 + (scrollPosition / 100) }} />


                        <Animated.View style={{ width: '79%', height: 50, marginVertical: 30, alignItems: 'flex-start', opacity: -16 + (scrollPosition / 100) }}>

                            <TouchableOpacity onPress={() => { navigation.navigate("QuizScreen") }}>

                                <LinearGradient
                                    colors={['#FF5733', '#A83279']}
                                    style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                                >

                                    <AppText text={"Quiz"} style={{ fontSize: 16, color: colors.white }} />

                                </LinearGradient>

                            </TouchableOpacity>

                        </Animated.View>

                    </View>


                    <View style={{ width: '100%', height: '50%', alignItems: 'flex-end', overflow: 'visible', marginTop: 60, paddingRight: 20 }}>


                        <AppText text={"Who is the all time best goal scorer for Liverpool FC?"} style={{ fontSize: 23, color: colors.white, textAlign: 'left', width: '76%', opacity: -17.5 + (scrollPosition / 100) }} />
                        <Animated.View style={{ width: '76%', alignItems: 'center', opacity: -18.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"Mo Salah"} gradientSize={50} gradient={true} left={true} />
                        </Animated.View>

                    </View>




                </Animated.View>}

                {width > 650 && <Animated.View style={{ width: width, height: height, opacity: -16 + (scrollPosition / 100), zIndex: 1, flexDirection: 'row' }}>

                    <View style={{ width: '60%', height: '100%', alignItems: 'center', overflow: 'visible' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -27.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"TROPHIES"} gradientSize={width / 8.5} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"See all the trophies and the winning goals for Liverpool FC"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '76%', opacity: -29.5 + (scrollPosition / 100) }} />


                        <Animated.View style={{ width: '76%', height: 50, marginVertical: 30, alignItems: 'flex-start', opacity: -31.5 + (scrollPosition / 100) }}>

                            <TouchableOpacity onPress={() => { navigation.navigate("TrophiesScreen") }}>

                                <LinearGradient
                                    colors={['#FF5733', '#A83279']}
                                    style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                                >

                                    <AppText text={"See"} style={{ fontSize: 16, color: colors.white }} />

                                </LinearGradient>

                            </TouchableOpacity>

                        </Animated.View>

                    </View>



                    <View style={{ width: '40%', height: '80%', justifyContent: 'center', alignItems: 'center', overflow: 'visible', marginTop: 120 }}>

                        <Animated.View style={{ position: 'absolute', top: 100, right: 50 }}>


                            <Animated.View style={{ width: '100%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`HERE `} style={{ fontSize: 40, color: colors.white, textAlign: 'left', opacity: -32.35 + (scrollPosition / 100) }} />

                                <AppText text={`IS `} style={{ fontSize: 40, color: colors.white, textAlign: 'left', opacity: -32.45 + (scrollPosition / 100) }} />
                            </Animated.View>

                            <Animated.View style={{ width: '100%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), marginTop: 0, zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`ORIGI `} style={{ fontSize: 40, color: colors.white, textAlign: 'left', opacity: -32.55 + (scrollPosition / 100) }} />

                                <AppText text={`WITH `} style={{ fontSize: 40, color: colors.white, textAlign: 'left', opacity: -32.55 + (scrollPosition / 100) }} />
                            </Animated.View>

                            <Animated.View style={{ width: '100%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), marginTop: 0, zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`A  `} style={{ fontSize: 40, color: colors.white, textAlign: 'left', opacity: -32.85 + (scrollPosition / 100) }} />

                                <AppText text={`SHOT... `} style={{ fontSize: 40, color: colors.white, textAlign: 'left', opacity: -32.95 + (scrollPosition / 100) }} />
                            </Animated.View>

                        </Animated.View>

                        <Animated.View style={{ position: 'absolute', bottom: 100, left: 40 }}>


                            <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`GERRA`} style={{ fontSize: 40, color: colors.white, textAlign: 'left', opacity: -33.65 + (scrollPosition / 100) }} />

                                <AppText text={`A`} style={{ fontSize: 40, color: colors.white, textAlign: 'left', opacity: -33.75 + (scrollPosition / 100) }} />
                                <AppText text={`A`} style={{ fontSize: 40, color: colors.white, textAlign: 'left', opacity: -33.85 + (scrollPosition / 100) }} />
                                <AppText text={`A`} style={{ fontSize: 40, color: colors.white, textAlign: 'left', opacity: -33.95 + (scrollPosition / 100) }} />
                                <AppText text={`RD!`} style={{ fontSize: 40, color: colors.white, textAlign: 'left', opacity: -34.05 + (scrollPosition / 100) }} />
                            </Animated.View>


                        </Animated.View>

                        <Animated.View style={{ position: 'absolute', left: 0 }}>


                            <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`CORNER`} style={{ fontSize: 40, color: colors.white, textAlign: 'left', opacity: -33.65 + (scrollPosition / 100) }} />
                            </Animated.View>

                            <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`TAKEN`} style={{ fontSize: 40, color: colors.white, textAlign: 'left', opacity: -34 + (scrollPosition / 100) }} />
                            </Animated.View>

                            <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`QUICKLY..`} style={{ fontSize: 40, color: colors.white, textAlign: 'left', opacity: -34.4 + (scrollPosition / 100) }} />
                            </Animated.View>


                        </Animated.View>
                    </View>





                </Animated.View>}

                {width < 650 && <Animated.View style={{ width: width, height: height, opacity: -16 + (scrollPosition / 100), zIndex: 1 }}>

                    <View style={{ width: '100%', height: '30%', alignItems: 'center', overflow: 'visible' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -20 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"TROPHIES"} gradientSize={width / 7} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"See all the trophies and the winning goals for Liverpool FC"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '76%', opacity: -21.5 + (scrollPosition / 100) }} />


                        <Animated.View style={{ width: '76%', height: 50, marginVertical: 30, alignItems: 'flex-start', opacity: -22.5 + (scrollPosition / 100) }}>

                            <TouchableOpacity onPress={() => { navigation.navigate("TrophiesScreen") }}>

                                <LinearGradient
                                    colors={['#FF5733', '#A83279']}
                                    style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                                >

                                    <AppText text={"See"} style={{ fontSize: 16, color: colors.white }} />

                                </LinearGradient>

                            </TouchableOpacity>

                        </Animated.View>

                    </View>



                    <View style={{ width: '100%', height: '50%', justifyContent: 'center', alignItems: 'center', overflow: 'visible', marginTop: 120 }}>

                        <Animated.View style={{ position: 'absolute', top: 0, right: 50 }}>


                            <Animated.View style={{ width: '100%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`HERE `} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -23.35 + (scrollPosition / 100) }} />

                                <AppText text={`IS `} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -23.45 + (scrollPosition / 100) }} />
                            </Animated.View>

                            <Animated.View style={{ width: '100%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), marginTop: 0, zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`ORIGI `} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -23.55 + (scrollPosition / 100) }} />

                                <AppText text={`WITH `} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -23.55 + (scrollPosition / 100) }} />
                            </Animated.View>

                            <Animated.View style={{ width: '100%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), marginTop: 0, zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`A  `} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -23.85 + (scrollPosition / 100) }} />

                                <AppText text={`SHOT... `} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -23.95 + (scrollPosition / 100) }} />
                            </Animated.View>

                        </Animated.View>

                        <Animated.View style={{ position: 'absolute', bottom: 10, right: 60 }}>


                            <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`GERRA`} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -25 + (scrollPosition / 100) }} />

                                <AppText text={`A`} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -25.5 + (scrollPosition / 100) }} />
                                <AppText text={`A`} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -26 + (scrollPosition / 100) }} />
                                <AppText text={`A`} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -26.5 + (scrollPosition / 100) }} />
                                <AppText text={`RD!`} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -27 + (scrollPosition / 100) }} />
                            </Animated.View>


                        </Animated.View>

                        <Animated.View style={{ position: 'absolute', left: 20 }}>


                            <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`CORNER`} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -24.5 + (scrollPosition / 100) }} />
                            </Animated.View>

                            <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`TAKEN`} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -25 + (scrollPosition / 100) }} />
                            </Animated.View>

                            <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -6.2 + (scrollPosition / 100), zIndex: 1, flexDirection: 'row' }}>
                                <AppText text={`QUICKLY..`} style={{ fontSize: 30, color: colors.white, textAlign: 'left', opacity: -25.5 + (scrollPosition / 100) }} />
                            </Animated.View>


                        </Animated.View>
                    </View>





                </Animated.View>}

                {width > 650 && <View style={{ width: width, alignItems: 'center' }}>

                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -36.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"LEGENDARY MOMENTS"} gradientSize={width / 10} gradient={true} />
                    </Animated.View>

                    <AppText text={"Watch some of the best Liverpool FC Legendary Games"} style={{ fontSize: 23, color: colors.white, width: '76%', opacity: -38 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '76%', height: 50, marginVertical: 30, alignItems: 'center', opacity: -39.5 + (scrollPosition / 100) }}>

                        <TouchableOpacity onPress={() => { navigation.navigate("LegendaryMomentsScreen") }}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >

                                <AppText text={"Watch"} style={{ fontSize: 16, color: colors.white }} />

                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <View style={{ width: '90%', height: 400, flexDirection: 'row', justifyContent: 'center' }}>

                        <Animated.Image style={{ width: '30%', height: '80%', opacity: - 40 + (scrollPosition / 100), borderRadius: 30, marginHorizontal: 20 }} source={{ uri: "https://media.cnn.com/api/v1/images/stellar/prod/150529184248-liverpool-champions-league-trophy.jpg?q=w_2970,h_1845,x_0,y_0,c_fill", }} />

                        <Animated.Image style={{ width: '30%', height: '80%', opacity: - 41 + (scrollPosition / 100), borderRadius: 30, marginHorizontal: 20, transform: [{ translateY: scrollPosition < 4650 ? (scrollPosition / 100 > 43 ? (scrollPosition) - 4300 : 0) : 350 }, { translateX: scrollPosition < 4650 ? (scrollPosition / 100 > 43 ? (scrollPosition) - 4300 : 0) : 350 }, { rotateY: scrollPosition < 4650 ? (scrollPosition / 100 > 43 ? (scrollPosition / 10) - 430 + 'deg' : 0 + 'deg') : 35 + 'deg' }] }} source={{ uri: "https://d3j2s6hdd6a7rg.cloudfront.net/v2/uploads/media/default/0002/33/thumb_132114_default_news_size_5.jpeg", }} />

                        <Animated.Image style={{ width: '30%', height: '80%', opacity: - 42 + (scrollPosition / 100), borderRadius: 30, marginHorizontal: 20 }} source={{ uri: "https://ss-i.thgim.com/public/incoming/7pov4u/article66585294.ece/alternates/LANDSCAPE_1200/Britain_Soccer_Premier_League_23833.jpg", }} />

                    </View>


                </View>}

                {width > 650 && <View style={{ width: width, height: 350, marginTop: - 50, alignItems: 'center', }}>


                    <Animated.View style={{ width: '50%', alignItems: 'center', opacity: -46 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"LIV 4 - 0 BAR"} gradientSize={80} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={"The Miracle At Anfield"} style={{ fontSize: 23, color: colors.white, width: '50%', textAlign: 'left', opacity: -46.5 + (scrollPosition / 100) }} />

                </View>}

                {width < 650 && <View style={{ width: width, alignItems: 'center' }}>

                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -26.8 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"LEGENDARY MOMENTS"} gradientSize={width / 10} gradient={true} />
                    </Animated.View>

                    <AppText text={"Watch some of the best Liverpool FC Legendary Games"} style={{ fontSize: 16, color: colors.white, width: '80%', opacity: -27.4 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '76%', height: 50, marginVertical: 30, alignItems: 'center', opacity: -27.5 + (scrollPosition / 100) }}>

                        <TouchableOpacity onPress={() => { navigation.navigate("LegendaryMomentsScreen") }}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >

                                <AppText text={"Watch"} style={{ fontSize: 16, color: colors.white }} />

                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <View style={{ width: '90%', height: 200, flexDirection: 'row', justifyContent: 'center' }}>

                        <Animated.Image style={{ width: '31%', height: '100%', opacity: - 28 + (scrollPosition / 100), borderRadius: 20, marginHorizontal: 10 }} source={{ uri: "https://media.cnn.com/api/v1/images/stellar/prod/150529184248-liverpool-champions-league-trophy.jpg?q=w_2970,h_1845,x_0,y_0,c_fill", }} />

                        <Animated.Image style={{ width: '31%', height: '100%', opacity: - 28.5 + (scrollPosition / 100), borderRadius: 20, marginHorizontal: 10, transform: [{ translateY: scrollPosition < 3310 ? (scrollPosition / 100 > 29.5 ? (scrollPosition) - 2950 : 0) : 360 }, { rotateY: scrollPosition < 3310 ? (scrollPosition / 100 > 29.5 ? (scrollPosition) - 2950 + 'deg' : 0 + 'deg') : 0 + 'deg' }] }} source={{ uri: "https://d3j2s6hdd6a7rg.cloudfront.net/v2/uploads/media/default/0002/33/thumb_132114_default_news_size_5.jpeg", }} />

                        <Animated.Image style={{ width: '31%', height: '100%', opacity: - 29 + (scrollPosition / 100), borderRadius: 20, marginHorizontal: 10 }} source={{ uri: "https://ss-i.thgim.com/public/incoming/7pov4u/article66585294.ece/alternates/LANDSCAPE_1200/Britain_Soccer_Premier_League_23833.jpg", }} />

                    </View>


                </View>}

                {width < 650 && <View style={{ width: width, height: 420, alignItems: 'center', paddingTop: 40 }}>


                    <Animated.View style={{ width: '100%', alignItems: 'center', opacity: -32.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"LIV 4 - 0 BAR"} gradientSize={40} gradient={true} />
                    </Animated.View>

                    <AppText text={"The Miracle At Anfield"} style={{ fontSize: 16, color: colors.white, width: '50%', opacity: -33 + (scrollPosition / 100) }} />

                </View>}





                {width > 650 && <View style={{ width: width, alignItems: 'center' }}>

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -48.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"FUN FACTS"} gradientSize={width / 8} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={"Here are some of the most interasting fun facts about Liverpool FC"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '80%', opacity: -49.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'flex-start', opacity: -50 + (scrollPosition / 100), marginTop: 30 }}>

                        <TouchableOpacity style={{}}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 25, width: 25, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'flex-start', opacity: -50.5 + (scrollPosition / 100) }}>

                        <TouchableOpacity style={{}}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 25, width: 25, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'flex-start', opacity: -51 + (scrollPosition / 100) }}>

                        <TouchableOpacity style={{}}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 25, width: 25, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', alignItems: 'flex-start', opacity: -52.5 + (scrollPosition / 100) }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', justifyContent: 'center' }}>
                            <AppText text={"1982"} gradientSize={100} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={`Liverpool Football Club was founded in 1892 following a dispute between the Everton committee and John Houlding, the club's president. This led to the formation of Liverpool FC, and the team played its first match in September 1892.`} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '80%', opacity: -53 + (scrollPosition / 100) }} />

                    </Animated.View>


                    <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'flex-start', opacity: -55 + (scrollPosition / 100), marginTop: 30 }}>

                        <TouchableOpacity style={{}}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 25, width: 25, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'flex-start', opacity: -55.5 + (scrollPosition / 100) }}>

                        <TouchableOpacity style={{}}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 25, width: 25, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'flex-start', opacity: -56 + (scrollPosition / 100) }}>

                        <TouchableOpacity style={{}}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 25, width: 25, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', alignItems: 'flex-start', opacity: -57.5 + (scrollPosition / 100) }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', justifyContent: 'center' }}>
                            <AppText text={"Shankly Gates"} gradientSize={100} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={`The iconic Shankly Gates at Anfield, Liverpool's home stadium, are named after Bill Shankly, one of the club's most successful and revered managers. The gates bear the famous quote: "You'll Never Walk Alone," which has become the club's anthem.`} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '80%', opacity: -53 + (scrollPosition / 100) }} />

                    </Animated.View>


                    <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'flex-start', opacity: -58 + (scrollPosition / 100), marginTop: 30 }}>

                        <TouchableOpacity style={{}}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 25, width: 25, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'flex-start', opacity: -59.5 + (scrollPosition / 100) }}>

                        <TouchableOpacity style={{}}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 25, width: 25, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'flex-start', opacity: -60.5 + (scrollPosition / 100) }}>

                        <TouchableOpacity style={{}}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 25, width: 25, justifyContent: 'center', alignItems: 'center', borderRadius: 61.5, zIndex: 1 }}
                            >


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', alignItems: 'flex-start', opacity: -62.5 + (scrollPosition / 100) }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', justifyContent: 'center' }}>
                            <AppText text={"Success"} gradientSize={100} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={`Liverpool FC is the most successful club in England, winning 66 major trophies.`} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '80%', opacity: -63 + (scrollPosition / 100) }} />

                    </Animated.View>
                </View>}

                {width < 650 && <View style={{ width: width, alignItems: 'center' }}>

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -34.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"FUN FACTS"} gradientSize={width / 7} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={"Here are some of the most interasting fun facts about Liverpool FC"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '80%', opacity: -35.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'flex-start', opacity: -36 + (scrollPosition / 100), marginTop: 30 }}>

                        <TouchableOpacity style={{}}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 20, width: 20, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'flex-start', opacity: -36.5 + (scrollPosition / 100) }}>

                        <TouchableOpacity style={{}}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 20, width: 20, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'flex-start', opacity: -38 + (scrollPosition / 100) }}>

                        <TouchableOpacity style={{}}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 20, width: 20, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', alignItems: 'flex-start', opacity: -38.5 + (scrollPosition / 100) }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', justifyContent: 'center' }}>
                            <AppText text={"1982"} gradientSize={60} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={`Liverpool Football Club was founded in 1892 following a dispute between the Everton committee and John Houlding, the club's president. This led to the formation of Liverpool FC, and the team played its first match in September 1892.`} style={{ fontSize: 16, color: colors.white, textAlign: 'left', width: '80%', opacity: -39 + (scrollPosition / 100) }} />

                    </Animated.View>


                    <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'flex-start', opacity: -40.5 + (scrollPosition / 100), marginTop: 30 }}>

                        <TouchableOpacity style={{}}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 20, width: 20, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'flex-start', opacity: -41 + (scrollPosition / 100) }}>

                        <TouchableOpacity style={{}}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 20, width: 20, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'flex-start', opacity: -42 + (scrollPosition / 100) }}>

                        <TouchableOpacity style={{}}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 20, width: 20, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', alignItems: 'flex-start', opacity: -43.5 + (scrollPosition / 100) }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', justifyContent: 'center' }}>
                            <AppText text={"Shankly Gates"} gradientSize={60} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={`The iconic Shankly Gates at Anfield, Liverpool's home stadium, are named after Bill Shankly, one of the club's most successful and revered managers. The gates bear the famous quote: "You'll Never Walk Alone," which has become the club's anthem.`} style={{ fontSize: 16, color: colors.white, textAlign: 'left', width: '80%', opacity: -45 + (scrollPosition / 100) }} />

                    </Animated.View>


                    <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'flex-start', opacity: -46 + (scrollPosition / 100), marginTop: 30 }}>

                        <TouchableOpacity style={{}}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 20, width: 20, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'flex-start', opacity: -47.5 + (scrollPosition / 100) }}>

                        <TouchableOpacity style={{}}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 20, width: 20, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'flex-start', opacity: -48 + (scrollPosition / 100) }}>

                        <TouchableOpacity style={{}}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 20, width: 20, justifyContent: 'center', alignItems: 'center', borderRadius: 42.5, zIndex: 1 }}
                            >


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', alignItems: 'flex-start', opacity: -49.5 + (scrollPosition / 100) }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', justifyContent: 'center' }}>
                            <AppText text={"Success"} gradientSize={60} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={`Liverpool FC is the most successful club in England, winning 66 major trophies.`} style={{ fontSize: 16, color: colors.white, textAlign: 'left', width: '80%', opacity: -50.5 + (scrollPosition / 100) }} />

                    </Animated.View>
                </View>}

                {width > 650 && <View style={{ width: width, flexDirection: 'row' }}>

                    <View style={{ width: '50%', height: '100%', alignItems: 'center', overflow: 'visible', justifyContent: 'center' }}>



                        <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -67 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"WHEN YOU WALK"} gradientSize={80} gradient={true} />
                        </Animated.View>

                        <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 10, zIndex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                            <AppText text={`THROUGH `} style={{ fontSize: 30, color: colors.white, opacity: -67.5 + (scrollPosition / 100) }} />

                            <AppText text={`THE `} style={{ fontSize: 30, color: colors.white, opacity: -67.6 + (scrollPosition / 100) }} />

                            <AppText text={`STORM `} style={{ fontSize: 30, color: colors.white, opacity: -67.7 + (scrollPosition / 100) }} />

                            <AppText text={`HOLD`} style={{ fontSize: 30, color: colors.white, opacity: -67.8 + (scrollPosition / 100) }} />
                        </Animated.View>

                        <Animated.View style={{ width: '80%', alignItems: 'center', zIndex: 1, flexDirection: 'row', justifyContent: 'center' }}>

                            <AppText text={`YOUR `} style={{ fontSize: 30, color: colors.white, opacity: -67.9 + (scrollPosition / 100) }} />

                            <AppText text={`HEAD `} style={{ fontSize: 30, color: colors.white, opacity: -70 + (scrollPosition / 100) }} />

                            <AppText text={`UP `} style={{ fontSize: 30, color: colors.white, opacity: -70.1 + (scrollPosition / 100) }} />

                            <AppText text={`HIGH...`} style={{ fontSize: 30, color: colors.white, opacity: -70.2 + (scrollPosition / 100) }} />

                        </Animated.View>


                    </View>


                    <View style={{ width: '50%', height: '100%', alignItems: 'center', overflow: 'visible' }}>

                        <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -63.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"DETECTOR"} gradientSize={width / 10} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Detect all of the lyrics of the Liverpool FC Chants"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '86%', opacity: -65 + (scrollPosition / 100) }} />


                        <Animated.View style={{ width: '86%', height: 50, marginVertical: 30, alignItems: 'flex-start', opacity: -65.6 + (scrollPosition / 100) }}>

                            <TouchableOpacity onPress={() => { navigation.navigate("DetectorScreen") }}>

                                <LinearGradient
                                    colors={['#FF5733', '#A83279']}
                                    style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                                >

                                    <AppText text={"Detect"} style={{ fontSize: 16, color: colors.white }} />

                                </LinearGradient>

                            </TouchableOpacity>

                        </Animated.View>

                    </View>

                </View>}

                {width < 650 && <View style={{ width: width, height: height, paddingTop: 60 }}>




                    <View style={{ width: '100%', height: '45%', alignItems: 'center', overflow: 'visible' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -51.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"DETECTOR"} gradientSize={width / 6} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Detect all of the lyrics of the Liverpool FC Chants"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '76%', opacity: -52 + (scrollPosition / 100) }} />


                        <Animated.View style={{ width: '76%', height: 50, marginVertical: 30, alignItems: 'flex-start', opacity: -53 + (scrollPosition / 100) }}>

                            <TouchableOpacity onPress={() => { navigation.navigate("DetectorScreen") }}>

                                <LinearGradient
                                    colors={['#FF5733', '#A83279']}
                                    style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                                >

                                    <AppText text={"Detect"} style={{ fontSize: 16, color: colors.white }} />

                                </LinearGradient>

                            </TouchableOpacity>

                        </Animated.View>

                    </View>

                    <View style={{ width: '100%', height: '55%', alignItems: 'center', overflow: 'visible', justifyContent: 'center', paddingTop: 30 }}>



                        <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -55 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"WHEN YOU WALK"} gradientSize={50} gradient={true} />
                        </Animated.View>

                        <Animated.View style={{ width: '90%', alignItems: 'center', marginTop: 10, zIndex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                            <AppText text={`THROUGH `} style={{ fontSize: 20, color: colors.white, opacity: -55.5 + (scrollPosition / 100) }} />

                            <AppText text={`THE `} style={{ fontSize: 20, color: colors.white, opacity: -55.6 + (scrollPosition / 100) }} />

                            <AppText text={`STORM `} style={{ fontSize: 20, color: colors.white, opacity: -55.7 + (scrollPosition / 100) }} />

                            <AppText text={`HOLD`} style={{ fontSize: 20, color: colors.white, opacity: -55.8 + (scrollPosition / 100) }} />
                        </Animated.View>

                        <Animated.View style={{ width: '90%', alignItems: 'center', zIndex: 1, flexDirection: 'row', justifyContent: 'center' }}>

                            <AppText text={`YOUR `} style={{ fontSize: 20, color: colors.white, opacity: -55.9 + (scrollPosition / 100) }} />

                            <AppText text={`HEAD `} style={{ fontSize: 20, color: colors.white, opacity: -56 + (scrollPosition / 100) }} />

                            <AppText text={`UP `} style={{ fontSize: 20, color: colors.white, opacity: -56.1 + (scrollPosition / 100) }} />

                            <AppText text={`HIGH...`} style={{ fontSize: 20, color: colors.white, opacity: -56.2 + (scrollPosition / 100) }} />

                        </Animated.View>


                    </View>

                </View>}


                {width > 650 && <View style={{ width: width, alignItems: 'center' }}>

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -70 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"SUPPORT"} gradientSize={width / 7} gradient={true} />
                    </Animated.View>

                    <AppText text={"This website is made alone by me (: And it takes a lot of : "} style={{ fontSize: 20, color: colors.white, width: '86%', opacity: -70.5 + (scrollPosition / 100) }} />

                    <View style={{ width: width, flexDirection: 'row', justifyContent: 'center', marginVertical: 100 }}>


                        <Animated.View style={{ width: '33%', alignItems: 'center', opacity: -71 + (scrollPosition / 100), justifyContent: 'center', }}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 200, width: 200, justifyContent: 'center', alignItems: 'center', borderRadius: 100, zIndex: 1, marginVertical: 20 }}
                            >






                                <Animated.View style={{ width: 20, height: 150, transform: [{ rotate: (scrollPosition / 5) + "deg" }], alignItems: 'center' }}>
                                    <View style={{ width: 15, height: 75, borderRadius: 30, position: 'absolute', top: 0, backgroundColor: colors.black }}>
                                    </View>

                                </Animated.View>


                            </LinearGradient>

                            <AppText text={"TIME"} gradientSize={40} gradient={true} />

                        </Animated.View>

                        <Animated.View style={{ width: '33%', alignItems: 'center', opacity: -71.5 + (scrollPosition / 100), justifyContent: 'center', }}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 200, width: 150, alignItems: 'center', borderRadius: 10, zIndex: 1, marginVertical: 20, justifyContent: 'center' }}
                            >

                                <Animated.View style={{ width: '100%', right: '70%', height: 15, backgroundColor: colors.black, borderRadius: 30, marginVertical: 18, transform: [{ translateX: scrollPosition < 7150 ? 0 : (scrollPosition / 1.8) - 7150 / 1.8 }] }}>


                                </Animated.View>

                                <Animated.View style={{ width: '100%', right: '60%', height: 15, backgroundColor: colors.black, borderRadius: 30, marginVertical: 18, transform: [{ translateX: scrollPosition < 7200 ? 0 : (scrollPosition / 1.8) - 7200 / 1.8 }] }}>


                                </Animated.View>

                                <Animated.View style={{ width: '100%', right: '60%', height: 15, backgroundColor: colors.black, borderRadius: 30, marginVertical: 18, transform: [{ translateX: scrollPosition < 7200 ? 0 : (scrollPosition / 1.8) - 7250 / 1.8 }] }}>


                                </Animated.View>





                            </LinearGradient>

                            <AppText text={"EXPERIENCE"} gradientSize={40} gradient={true} />

                        </Animated.View>

                        <Animated.View style={{ width: '33%', alignItems: 'center', opacity: -72 + (scrollPosition / 100), justifyContent: 'center', }}>

                            <View style={{ width: 200, height: 200, overflow: 'hidden', marginVertical: 20 }}>


                                <LinearGradient
                                    colors={['#FF5733', '#A83279']}
                                    style={{ height: 200, width: 200, alignItems: 'center', borderRadius: 100, zIndex: 1, marginVertical: 20, justifyContent: 'center', position: 'absolute', bottom: -100 }}
                                >

                                    <Animated.View style={{ width: 15, right: 15, position: 'absolute', height: 15, backgroundColor: colors.black, borderRadius: 30, opacity: -72.5 + (scrollPosition / 100) }}>


                                    </Animated.View>

                                    <Animated.View style={{ width: 15, left: 25, top: 50, position: 'absolute', height: 15, backgroundColor: colors.black, borderRadius: 30, opacity: -72.7 + (scrollPosition / 100) }}>


                                    </Animated.View>

                                    <Animated.View style={{ width: 15, top: 40, right: 50, position: 'absolute', height: 15, backgroundColor: colors.black, borderRadius: 30, opacity: -72.9 + (scrollPosition / 100) }}>


                                    </Animated.View>

                                    <Animated.View style={{ width: 15, bottom: 100, left: 90, position: 'absolute', height: 15, backgroundColor: colors.black, borderRadius: 30, opacity: -73.1 + (scrollPosition / 100) }}>


                                    </Animated.View>



                                </LinearGradient>
                            </View>

                            <AppText text={"SPACE"} gradientSize={40} gradient={true} />

                        </Animated.View>

                    </View>

                    <AppText text={"So it would mean a world to me if you could buy me a cofee (:"} style={{ fontSize: 30, color: colors.white, width: '86%', opacity: 1 }} />

                    <Animated.View style={{ width: '86%', height: 50, marginVertical: 30, alignItems: 'center', opacity: 1 }}>

                        <TouchableOpacity onPress={() => { Linking.openURL("https://www.buymeacoffee.com/stabar1234a") }}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >

                                <AppText text={"Support"} style={{ fontSize: 16, color: colors.white }} />

                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>
                </View>}

                {width < 650 && <View style={{ width: width, alignItems: 'center' }}>
                    <View style={{ alignItems: 'center', height: 200 }}>
                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -57.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"SUPPORT"} gradientSize={width / 6} gradient={true} />
                        </Animated.View>

                        <AppText text={"This website is made alone by me (: And it takes a lot of : "} style={{ fontSize: 20, color: colors.white, width: '86%', opacity: -58 + (scrollPosition / 100) }} />
                    </View>

                    <View style={{ justifyContent: 'center', alignItems: 'center', width: width }}>


                        <Animated.View style={{ width: '33%', alignItems: 'center', opacity: -59 + (scrollPosition / 100), justifyContent: 'center', }}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 200, width: 200, justifyContent: 'center', alignItems: 'center', borderRadius: 100, zIndex: 1, marginVertical: 20 }}
                            >






                                <Animated.View style={{ width: 20, height: 150, transform: [{ rotate: (scrollPosition / 3) + "deg" }], alignItems: 'center' }}>
                                    <View style={{ width: 15, height: 75, borderRadius: 30, position: 'absolute', top: 0, backgroundColor: colors.black }}>
                                    </View>

                                </Animated.View>


                            </LinearGradient>

                            <AppText text={"TIME"} gradientSize={40} gradient={true} />

                        </Animated.View>

                        <Animated.View style={{ width: '33%', alignItems: 'center', opacity: -62 + (scrollPosition / 100), justifyContent: 'center', width: width }}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 200, width: 150, alignItems: 'center', borderRadius: 10, zIndex: 1, marginVertical: 20, justifyContent: 'center', overflow: 'hidden' }}
                            >

                                <Animated.View style={{ width: '100%', right: '70%', height: 15, backgroundColor: colors.black, borderRadius: 30, marginVertical: 18, transform: [{ translateX: scrollPosition < 6200 ? 0 : (scrollPosition / 1.8) - 6200 / 1.8 }] }}>


                                </Animated.View>

                                <Animated.View style={{ width: '100%', right: '60%', height: 15, backgroundColor: colors.black, borderRadius: 30, marginVertical: 18, transform: [{ translateX: scrollPosition < 6250 ? 0 : (scrollPosition / 1.8) - 6250 / 1.8 }] }}>


                                </Animated.View>

                                <Animated.View style={{ width: '100%', right: '60%', height: 15, backgroundColor: colors.black, borderRadius: 30, marginVertical: 18, transform: [{ translateX: scrollPosition < 6300 ? 0 : (scrollPosition / 1.8) - 6300 / 1.8 }] }}>


                                </Animated.View>





                            </LinearGradient>

                            <AppText text={"EXPERIENCE"} gradientSize={40} gradient={true} />

                        </Animated.View>

                        <Animated.View style={{ width: '33%', alignItems: 'center', opacity: -63 + (scrollPosition / 100), justifyContent: 'center', width: width }}>

                            <View style={{ width: 200, height: 200, overflow: 'hidden', marginVertical: 20 }}>


                                <LinearGradient
                                    colors={['#FF5733', '#A83279']}
                                    style={{ height: 200, width: 200, alignItems: 'center', borderRadius: 100, zIndex: 1, marginVertical: 20, justifyContent: 'center', position: 'absolute', bottom: -100 }}
                                >

                                    <Animated.View style={{ width: 15, right: 15, position: 'absolute', height: 15, backgroundColor: colors.black, borderRadius: 30, opacity: -65.5 + (scrollPosition / 100) }}>


                                    </Animated.View>

                                    <Animated.View style={{ width: 15, left: 25, top: 50, position: 'absolute', height: 15, backgroundColor: colors.black, borderRadius: 30, opacity: -65.7 + (scrollPosition / 100) }}>


                                    </Animated.View>

                                    <Animated.View style={{ width: 15, top: 40, right: 50, position: 'absolute', height: 15, backgroundColor: colors.black, borderRadius: 30, opacity: -65.9 + (scrollPosition / 100) }}>


                                    </Animated.View>

                                    <Animated.View style={{ width: 15, bottom: 100, left: 90, position: 'absolute', height: 15, backgroundColor: colors.black, borderRadius: 30, opacity: -66.1 + (scrollPosition / 100) }}>


                                    </Animated.View>



                                </LinearGradient>
                            </View>

                            <AppText text={"AND SPACE"} gradientSize={40} gradient={true} />

                        </Animated.View>

                    </View>

                    <AppText text={"So it would mean a world to me if you could buy me a cofee (:"} style={{ fontSize: 20, marginTop: 70, color: colors.white, width: '86%', opacity: 1 }} />

                    <Animated.View style={{ width: '86%', height: 50, marginVertical: 30, alignItems: 'center', opacity: 1 }}>

                        <TouchableOpacity onPress={() => { Linking.openURL("https://www.buymeacoffee.com/stabar1234a") }}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >

                                <AppText text={"Support"} style={{ fontSize: 16, color: colors.white }} />

                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>
                </View>}
            </View>
        </ScrollView>
    );
}

export default MainScreen;