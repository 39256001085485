import { StatusBar } from 'expo-status-bar';
import { StyleSheet, Text, View } from 'react-native';
import Navigation from './app/navigation/Navigation';
import { NavigationContainer } from '@react-navigation/native';


import MainScreen from './app/screens/MainScreen';
import StatsScreen from './app/screens/StatsScreen';
import QuizScreen from './app/screens/QuizScreen';
import TrophiesScreen from './app/screens/TrophiesScreen';
import LegendaryMomentsScreen from './app/screens/LegendaryMomentsScreen';
import DetectorScreen from './app/screens/DetectorScreen';

export default function App() {
  return (

    <NavigationContainer>

      <Navigation />

    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
