import React, { useEffect, useRef, useState } from 'react';
import { View, Dimensions, Animated, FlatList, Image, TouchableOpacity, ScrollView } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Lottie from "lottie-react";

import AppText from '../components/AppText';
import colors from '../colors/colors';


const questions = [

    { id: 1, text: "In which year was Liverpool FC founded?", a: "1872", b: " 1892", c: "1902", d: "1920", correct: "1892", image: "https://herbyzbliska.files.wordpress.com/2021/06/bill-shankly-gates-anfield-kevin-elias.jpg" },
    { id: 2, text: "Who is Liverpool FC's all-time appearance record holder?", a: "Ian Callaghan", b: "Jamie Carragher", c: "Steven Gerrard", d: "Kenny Dalglish", correct: "Ian Callaghan", image: "https://www.thisisanfield.com/wp-content/uploads/Squadphoto2324.jpg" },
    { id: 3, text: "Which club did Liverpool defeat in the 2005 UEFA Champions League final, famously coming back from 3-0 down at halftime?", a: "Real Madrid", b: "Barcelona", c: "Juventus", d: "AC Milan", correct: "AC Milan", image: "https://bi.im-g.pl/im/73/95/14/z21585267V,Jerzy-Dudek-i-Andrij-Szewczenko.jpg" },
    { id: 4, text: "Which player holds the record for the most goals scored in a single season for Liverpool FC?", a: "Fernando Torres", b: "Ian Rush", c: "Mohamed Salah", d: "Robbie Fowler", correct: "Mohamed Salah", image: "https://cdn.getyourguide.com/img/tour/b119c9343da4a660.jpeg/145.jpg" },
    { id: 5, text: "Who is Liverpool FC's all-time leading assist provider?", a: "Steven Gerrard", b: "Kenny Dalglish", c: " Trent Alexander-Arnold", d: "Luis Suarez", correct: "Steven Gerrard", image: "https://i.guim.co.uk/img/media/dfc6301050e2e292720ba85b54dbaaed8e9d31b4/0_56_5568_3341/master/5568.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=88f9725390a433b3ac0dacccf999dd68" },
    { id: 6, text: "In which year did Liverpool FC complete a historic comeback against Borussia Dortmund in the UEFA Europa League quarter-finals?", a: "2014", b: "2019", c: "2018", d: "2016", correct: "2016", image: "https://www.thisisanfield.com/wp-content/uploads/P160414-169-Liverpool_Dortmund-1-e1492159205371.jpg" },
    { id: 7, text: "How many English top-flight league titles did Liverpool FC win before the Premier League era?", a: "15", b: "18", c: "20", d: "22", correct: "18", image: "https://e0.365dm.com/20/07/1600x900/skysports-henderson-liverpool_5047014.jpg?20200723001128" },
    { id: 8, text: "Who scored the winning goal for Liverpool FC in the 2019-2020 Premier League season to secure their title?", a: "Sadio Mane", b: "Mohamed Salah", c: "Jordan Henderson", d: "Divock Origi", correct: "Jordan Henderson", image: "https://d3j2s6hdd6a7rg.cloudfront.net/v2/uploads/media/default/0001/96/thumb_95168_default_news_size_5.jpeg" },
    { id: 9, text: "Which defender is known for his long-range goals and is a key part of Liverpool's defensive lineup?", a: "Andrew Robertson", b: "Trent Alexander-Arnold", c: "Joel Matip", d: "Virgil van Dijk", correct: "Trent Alexander-Arnold", image: "https://prod-media.beinsports.com/image/1682990965543_5ca63776-9f57-4252-bd96-8d3ce5123562.jpg" },
    { id: 10, text: "Who is Liverpool FC's all-time top scorer in European competitions?", a: "Steven Gerrard", b: " Ian Rush", c: "Robbie Fowler", d: "Mohamed Salah", correct: "Mohamed Salah", image: "https://addons-media.operacdn.com/media/CACHE/images/themes/35/178835/1.1-rev1/images/b077a974-8423-4c55-8ac7-6dcb3fbf66c6/a0aadf9c6685fb90cace15bb1c649f90.jpg" },
]


const height = window.innerHeight;
const width = window.innerWidth;

function QuizScreen({ navigation }) {

    const quizTextOpacity = useState(new Animated.Value(0))[0];

    const startOpacity = useState(new Animated.Value(0))[0];

    const exitOpacity = useState(new Animated.Value(0))[0];

    const quizOpacity = useState(new Animated.Value(0))[0];

    const summaryOpacity = useState(new Animated.Value(0))[0];

    const startScreenopacity = useState(new Animated.Value(1))[0];

    const [currentQuestion, setCurrentQuestion] = useState(0);

    const [startQuizIndex, setStartQuizIndex] = useState(0);

    const [yourAnswers, setYourAnswers] = useState([]);

    const [points, setPoints] = useState(0);


    const answer = (answer) => {

        if (answer === questions[currentQuestion].correct) {
            setPoints(points + 1);
        }

        if (currentQuestion < 9) {

            setCurrentQuestion(currentQuestion + 1)
        }
        else {


            endQuiz();
        }

        setYourAnswers(yourAnswers + " " + answer)


    }


    const endQuiz = () => {

        setStartQuizIndex(-1);

        Animated.timing(quizOpacity, {
            toValue: 0,
            duration: 800,
            useNativeDriver: true,
        }).start();

        setTimeout(function () {
            Animated.timing(summaryOpacity, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 500)


    }

    const startQuiz = () => {

        setStartQuizIndex(1);

        Animated.timing(startScreenopacity, {
            toValue: 0,
            duration: 800,
            useNativeDriver: true,
        }).start();

        setTimeout(function () {
            Animated.timing(quizOpacity, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 500)
    }

    const [scrollPosition, setScrollPosition] = useState(0);

    const toogleOpacities = () => {

        Animated.timing(quizTextOpacity, {
            toValue: 1,
            duration: 800,
            useNativeDriver: true,
        }).start();

        setTimeout(function () {
            Animated.timing(startOpacity, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 500)

        setTimeout(function () {
            Animated.timing(exitOpacity, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 800)

    };

    useEffect(() => {

        toogleOpacities();

    }, [])



    return (
        <ScrollView onScroll={(event) => {

            setScrollPosition(event.nativeEvent.contentOffset.y)
        }} scrollEventThrottle={16}>
            <View style={{ width: width, height: height, alignItems: 'center', backgroundColor: colors.black }}>





                <Animated.View style={{ width: width, height: height, alignItems: 'center', justifyContent: 'center', opacity: startScreenopacity, zIndex: 1, position: 'absolute' }}>

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: quizTextOpacity, justifyContent: 'center' }}>
                        <AppText text={"QUIZ"} gradientSize={width > 650 ? width / 6 : 80} gradient={true} />
                        <AppText text={"Test your ball knowladge and play the quiz!"} style={{ fontSize: 20, color: colors.white }} />
                    </Animated.View>

                    <Animated.View style={{ width: '79%', height: 50, marginVertical: 30, alignItems: 'center', opacity: startOpacity }}>

                        <TouchableOpacity onPress={() => { startQuiz() }}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >

                                <AppText text={"Start"} style={{ fontSize: 16, color: colors.white }} />

                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>
                </Animated.View>


                <Animated.View style={{ width: width, height: height, alignItems: 'center', zIndex: startQuizIndex, justifyContent: 'center', paddingTop: 50, opacity: quizOpacity, position: 'absolute' }}>

                    <AppText text={questions[currentQuestion].text} style={{ fontSize: width > 650 ? 30 : 16, color: colors.white, width: '90%' }} />

                    {width > 650 &&

                        <View style={{ width: 300, height: 300, justifyContent: 'center', alignItems: 'center', marginVertical: 50 }}>
                            <Lottie animationData={require("../assets/animations/loading.json")} loop={true} style={{ width: 100, height: 100, position: 'absolute' }} />
                            <Image style={{ width: 300, height: 300, borderRadius: 20, }} source={{ uri: questions[currentQuestion].image }} />
                        </View>}

                    {width < 650 &&

                        <View style={{ width: 200, height: 200, justifyContent: 'center', alignItems: 'center', marginVertical: 50 }}>
                            <Lottie animationData={require("../assets/animations/loading.json")} loop={true} style={{ width: 100, height: 100, position: 'absolute' }} />
                            <Image style={{ width: 200, height: 200, borderRadius: 20 }} source={{ uri: questions[currentQuestion].image }} />
                        </View>}

                    <View style={{ flexDirection: 'row' }}>

                        <TouchableOpacity onPress={() => { answer(questions[currentQuestion].a) }} style={{ marginHorizontal: 10 }}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 10, zIndex: 1 }}
                            >

                                <AppText text={questions[currentQuestion].a} style={{ fontSize: 16, color: colors.white }} />

                            </LinearGradient>

                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => { answer(questions[currentQuestion].b) }} style={{ marginHorizontal: 10 }}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 10, zIndex: 1 }}
                            >

                                <AppText text={questions[currentQuestion].b} style={{ fontSize: 16, color: colors.white }} />

                            </LinearGradient>

                        </TouchableOpacity>

                    </View>

                    <View style={{ flexDirection: 'row', marginTop: 20 }} >

                        <TouchableOpacity onPress={() => { answer(questions[currentQuestion].c) }} style={{ marginHorizontal: 10 }}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 10, zIndex: 1 }}
                            >

                                <AppText text={questions[currentQuestion].c} style={{ fontSize: 16, color: colors.white }} />

                            </LinearGradient>

                        </TouchableOpacity>

                        <TouchableOpacity onPress={() => { answer(questions[currentQuestion].d) }} style={{ marginHorizontal: 10 }}>

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 45, width: 160, justifyContent: 'center', alignItems: 'center', borderRadius: 10, zIndex: 1 }}
                            >

                                <AppText text={questions[currentQuestion].d} style={{ fontSize: 16, color: colors.white }} />

                            </LinearGradient>

                        </TouchableOpacity>

                    </View>

                    {width > 650 && <Animated.View style={{ width: 50, height: 50, alignItems: 'center', zIndex: 1, justifyContent: 'center', position: 'absolute', top: 60, right: 50 }}>


                        <TouchableOpacity >

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >

                                <AppText text={(currentQuestion + 1) + "/10"} style={{ fontSize: 16, color: colors.white }} />


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>}

                    {width < 650 && <Animated.View style={{ width: 30, height: 30, alignItems: 'center', zIndex: 1, justifyContent: 'center', position: 'absolute', top: 20, right: 20 }}>


                        <TouchableOpacity >

                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 30, width: 30, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                            >

                                <AppText text={(currentQuestion + 1) + "/10"} style={{ fontSize: 13, color: colors.white }} />


                            </LinearGradient>

                        </TouchableOpacity>

                    </Animated.View>}

                </Animated.View>

                <Animated.View style={{ width: width, height: height, alignItems: 'center', justifyContent: 'center', opacity: summaryOpacity, zIndex: startQuizIndex == -1 ? 1 : 0 }}>

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: 1, justifyContent: 'center' }}>
                        <AppText text={points + "pts"} gradientSize={width > 650 ? width / 6 : 80} gradient={true} />
                        <AppText text={"Congratulations scroll down to reveal the answers"} style={{ fontSize: 20, color: colors.white }} />
                    </Animated.View>



                </Animated.View>





                {(startQuizIndex == -1 && width > 650) && <Animated.View style={{ width: width, alignItems: 'center', backgroundColor: colors.black, opacity: summaryOpacity, zIndex: quizOpacity == -1 ? 1 : 0 }}>


                    <AppText text={questions[0].text} style={{ fontSize: 30, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -2.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -3 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[0].correct} gradientSize={80} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={questions[1].text} style={{ fontSize: 30, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -3.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -4 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[1].correct} gradientSize={80} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={questions[2].text} style={{ fontSize: 30, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -5.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -6 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[2].correct} gradientSize={80} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={questions[3].text} style={{ fontSize: 30, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -7.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -8 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[3].correct} gradientSize={80} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={questions[4].text} style={{ fontSize: 30, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -9.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -10 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[4].correct} gradientSize={80} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={questions[5].text} style={{ fontSize: 30, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -11.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -13 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[5].correct} gradientSize={80} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={questions[6].text} style={{ fontSize: 30, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -14.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -15 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[6].correct} gradientSize={80} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={questions[7].text} style={{ fontSize: 30, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -16.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -17 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[7].correct} gradientSize={80} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={questions[8].text} style={{ fontSize: 30, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -18.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -19 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[8].correct} gradientSize={80} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={questions[9].text} style={{ fontSize: 30, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -20.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -21 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[9].correct} gradientSize={80} gradient={true} left={true} />
                    </Animated.View>




                </Animated.View>}

                {(startQuizIndex == -1 && width < 650) && <Animated.View style={{ width: width, alignItems: 'center', backgroundColor: colors.black, opacity: summaryOpacity, zIndex: quizOpacity == -1 ? 1 : 0 }}>


                    <AppText text={questions[0].text} style={{ fontSize: 20, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -2.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -3 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[0].correct} gradientSize={60} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={questions[1].text} style={{ fontSize: 20, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -3.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -4 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[1].correct} gradientSize={60} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={questions[2].text} style={{ fontSize: 20, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -5.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -6 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[2].correct} gradientSize={60} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={questions[3].text} style={{ fontSize: 20, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -7.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -9 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[3].correct} gradientSize={60} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={questions[4].text} style={{ fontSize: 20, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -10.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -11 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[4].correct} gradientSize={60} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={questions[5].text} style={{ fontSize: 20, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -12.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -13 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[5].correct} gradientSize={60} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={questions[6].text} style={{ fontSize: 20, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -14.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -15.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[6].correct} gradientSize={60} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={questions[7].text} style={{ fontSize: 20, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -16.5 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -18 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[7].correct} gradientSize={60} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={questions[8].text} style={{ fontSize: 20, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -20 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -21 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[8].correct} gradientSize={60} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={questions[9].text} style={{ fontSize: 20, color: colors.white, marginTop: 50, textAlign: 'left', width: '80%', opacity: -24 + (scrollPosition / 100) }} />

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -25 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={questions[9].correct} gradientSize={60} gradient={true} left={true} />
                    </Animated.View>


                    <View style={{ width: 200, height: 200 }}>

                    </View>

                </Animated.View>}



                {width > 650 && <Animated.View style={{ width: 50, height: 50, alignItems: 'center', zIndex: 1, opacity: exitOpacity, justifyContent: 'center', position: 'absolute', top: 50, left: 50 }}>


                    <TouchableOpacity onPress={() => navigation.goBack()}>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                        >

                            <MaterialCommunityIcons name='chevron-left' size={30} color={colors.white} />


                        </LinearGradient>

                    </TouchableOpacity>

                </Animated.View>}

                {width < 650 && <Animated.View style={{ width: 30, height: 30, alignItems: 'center', zIndex: 1, opacity: exitOpacity, justifyContent: 'center', position: 'absolute', top: 20, left: 20 }}>


                    <TouchableOpacity onPress={() => navigation.goBack()}>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 30, width: 30, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                        >

                            <MaterialCommunityIcons name='chevron-left' size={25} color={colors.white} />


                        </LinearGradient>

                    </TouchableOpacity>

                </Animated.View>}


            </View>
        </ScrollView>
    );
}

export default QuizScreen;