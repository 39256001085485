import React from "react";
import { createNativeStackNavigator } from '@react-navigation/native-stack';


import MainScreen from "../screens/MainScreen";
import SongsScreen from "../screens/SongsScreen";
import FullSong from "../screens/FullSong";
import StatsScreen from "../screens/StatsScreen";
import QuizScreen from "../screens/QuizScreen";
import TrophiesScreen from "../screens/TrophiesScreen";
import LegendaryMomentsScreen from "../screens/LegendaryMomentsScreen";
import FullVideo from "../screens/FullVideo";
import DetectorScreen from "../screens/DetectorScreen";



const Stack = createNativeStackNavigator();

const Navigation = () => (

  <Stack.Navigator>

    <Stack.Screen name="MainScreen" component={MainScreen} options={{ headerShown: false }} />
    <Stack.Screen name="SongsScreen" component={SongsScreen} options={{ headerShown: false }} />
    <Stack.Screen name="FullSong" component={FullSong} options={{ headerShown: false }} />
    <Stack.Screen name="StatsScreen" component={StatsScreen} options={{ headerShown: false }} />
    <Stack.Screen name="QuizScreen" component={QuizScreen} options={{ headerShown: false }} />
    <Stack.Screen name="TrophiesScreen" component={TrophiesScreen} options={{ headerShown: false }} />
    <Stack.Screen name="LegendaryMomentsScreen" component={LegendaryMomentsScreen} options={{ headerShown: false }} />
    <Stack.Screen name="FullVideo" component={FullVideo} options={{ headerShown: false }} />
    <Stack.Screen name="DetectorScreen" component={DetectorScreen} options={{ headerShown: false }} />

  </Stack.Navigator>
);

export default Navigation;