import React, { useEffect, useRef, useState } from 'react';
import { View, Dimensions, Animated, FlatList, Image, TouchableOpacity, ScrollView } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import YouTube from 'react-youtube';

import AppText from '../components/AppText';
import colors from '../colors/colors';



const height = window.innerHeight;
const width = window.innerWidth;

function FullSong({ navigation, route }) {

    const item = route.params;

    const ListOpacity = useState(new Animated.Value(0))[0];

    const SongsTextOpacity = useState(new Animated.Value(0))[0];

    const exitOpacity = useState(new Animated.Value(0))[0];

    const videoOpacity = useState(new Animated.Value(0))[0];

    const lyricsOpacity = useState(new Animated.Value(0))[0];

    const AboutWhoOpacity = useState(new Animated.Value(0))[0];

    const toogleOpacities = () => {

        Animated.timing(SongsTextOpacity, {
            toValue: 1,
            duration: 800,
            useNativeDriver: true,
        }).start();

        setTimeout(function () {
            Animated.timing(AboutWhoOpacity, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 500)

        setTimeout(function () {
            Animated.timing(videoOpacity, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 800)

        setTimeout(function () {
            Animated.timing(exitOpacity, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 1100)

        setTimeout(function () {
            Animated.timing(lyricsOpacity, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 1400)

    };

    const [scrollPosition, setScrollPosition] = useState(0);


    useEffect(() => {

        toogleOpacities();

    }, [])

    const opts = {
        height: '200',
        width: '300',
        playerVars: {
            autoplay: 1,
        },
    };

    const optsPC = {

        playerVars: {
            autoplay: 1,
        },
    };


    return (
        <ScrollView onScroll={(event) => {
            setScrollPosition(event.nativeEvent.contentOffset.y)
        }} scrollEventThrottle={1} style={{ backgroundColor: colors.black }}>

            <View style={{ width: width, height: height, backgroundColor: colors.black, alignItems: 'center', paddingTop: 40 }}>


                {width > 650 && <Animated.View style={{ width: 50, height: 50, alignItems: 'center', opacity: exitOpacity, justifyContent: 'center', position: 'absolute', top: 50, left: 50 }}>


                    <TouchableOpacity onPress={() => navigation.goBack()}>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                        >

                            <MaterialCommunityIcons name='chevron-left' size={30} color={colors.white} />


                        </LinearGradient>

                    </TouchableOpacity>

                </Animated.View>}

                {width < 650 && <Animated.View style={{ width: 30, height: 30, alignItems: 'center', opacity: exitOpacity, justifyContent: 'center', position: 'absolute', top: 20, left: 20 }}>


                    <TouchableOpacity onPress={() => navigation.goBack()}>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 30, width: 30, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                        >

                            <MaterialCommunityIcons name='chevron-left' size={25} color={colors.white} />


                        </LinearGradient>

                    </TouchableOpacity>

                </Animated.View>}

                <Animated.View style={{ width: '80%', alignItems: 'center', opacity: SongsTextOpacity, justifyContent: 'center' }}>
                    <AppText text={item.name} gradientSize={width > 650 ? width / 10 : 40} gradient={true} />
                </Animated.View>

                <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 5, zIndex: 1 }}>
                    <AppText text={`"` + item.aboutWho + `"`} style={{ fontSize: 20, color: colors.white, opacity: AboutWhoOpacity }} />
                </Animated.View>

                {width > 650 && <Animated.View style={{ alignItems: 'center', width: 500, opacity: videoOpacity, marginVertical: 100 }}>
                    <YouTube videoId={item.videoId} opts={optsPC} />
                </Animated.View>}

                {width < 650 && <Animated.View style={{ alignItems: 'center', overflow: 'visible', opacity: videoOpacity, marginVertical: 100 }}>
                    <YouTube videoId={item.videoId} opts={opts} />
                </Animated.View>}



                <Animated.View style={{ width: '80%', alignItems: 'center', opacity: lyricsOpacity, justifyContent: 'center' }}>
                    <AppText text={"Lyrics"} gradientSize={30} gradient={true} />
                </Animated.View>




                {width > 650 && <>

                    <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 5, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[0].text + `"`} style={{ fontSize: 30, color: colors.white, opacity: -2 + (scrollPosition / 100) }} />
                    </Animated.View>

                    <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 5, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[1].text + `"`} style={{ fontSize: 30, color: colors.white, opacity: -2.5 + (scrollPosition / 100) }} />
                    </Animated.View>

                    {item.lines.length > 2 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 5, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[2].text + `"`} style={{ fontSize: 30, color: colors.white, opacity: -3 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 3 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 5, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[3].text + `"`} style={{ fontSize: 30, color: colors.white, opacity: -3.5 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 4 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 5, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[4].text + `"`} style={{ fontSize: 30, color: colors.white, opacity: -4 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 5 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 5, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[5].text + `"`} style={{ fontSize: 30, color: colors.white, opacity: -4.5 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 6 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 5, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[6].text + `"`} style={{ fontSize: 30, color: colors.white, opacity: -5 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 7 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 5, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[7].text + `"`} style={{ fontSize: 30, color: colors.white, opacity: -5.5 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 8 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 5, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[8].text + `"`} style={{ fontSize: 30, color: colors.white, opacity: -6 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 9 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 5, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[9].text + `"`} style={{ fontSize: 30, color: colors.white, opacity: -6.5 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 10 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 5, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[10].text + `"`} style={{ fontSize: 30, color: colors.white, opacity: -7 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 11 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[11].text + `"`} style={{ fontSize: 30, color: colors.white, opacity: -7.5 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 12 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[12].text + `"`} style={{ fontSize: 30, color: colors.white, opacity: -8 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 13 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[13].text + `"`} style={{ fontSize: 30, color: colors.white, opacity: -8.5 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 14 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[14].text + `"`} style={{ fontSize: 30, color: colors.white, opacity: -9 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 15 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[15].text + `"`} style={{ fontSize: 30, color: colors.white, opacity: -9.5 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 16 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[16].text + `"`} style={{ fontSize: 30, color: colors.white, opacity: -10 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 17 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[17].text + `"`} style={{ fontSize: 30, color: colors.white, opacity: -10.5 + (scrollPosition / 100) }} />
                    </Animated.View>}
                </>}

                {width < 650 && <>

                    <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 5, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[0].text + `"`} style={{ fontSize: 20, color: colors.white, opacity: -1.4 + (scrollPosition / 100) }} />
                    </Animated.View>

                    <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[1].text + `"`} style={{ fontSize: 20, color: colors.white, opacity: -2.5 + (scrollPosition / 100) }} />
                    </Animated.View>

                    {item.lines.length > 2 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[2].text + `"`} style={{ fontSize: 20, color: colors.white, opacity: -3 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 3 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[3].text + `"`} style={{ fontSize: 20, color: colors.white, opacity: -3.5 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 4 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[4].text + `"`} style={{ fontSize: 20, color: colors.white, opacity: -4 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 5 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[5].text + `"`} style={{ fontSize: 20, color: colors.white, opacity: -4.5 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 6 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[6].text + `"`} style={{ fontSize: 20, color: colors.white, opacity: -5 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 7 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[7].text + `"`} style={{ fontSize: 20, color: colors.white, opacity: -5.5 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 8 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[8].text + `"`} style={{ fontSize: 20, color: colors.white, opacity: -6 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 9 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[9].text + `"`} style={{ fontSize: 20, color: colors.white, opacity: -6.5 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 10 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[10].text + `"`} style={{ fontSize: 20, color: colors.white, opacity: -7 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 11 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[11].text + `"`} style={{ fontSize: 20, color: colors.white, opacity: -7.5 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 12 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[12].text + `"`} style={{ fontSize: 20, color: colors.white, opacity: -8 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 13 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[13].text + `"`} style={{ fontSize: 20, color: colors.white, opacity: -8.5 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 14 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[14].text + `"`} style={{ fontSize: 20, color: colors.white, opacity: -9 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 15 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[15].text + `"`} style={{ fontSize: 20, color: colors.white, opacity: -9.5 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 16 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[16].text + `"`} style={{ fontSize: 20, color: colors.white, opacity: -10 + (scrollPosition / 100) }} />
                    </Animated.View>}

                    {item.lines.length > 17 && <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 15, zIndex: 1 }}>
                        <AppText text={`"` + item.lines[17].text + `"`} style={{ fontSize: 20, color: colors.white, opacity: -10.5 + (scrollPosition / 100) }} />
                    </Animated.View>}

                </>}

                {width > 650 && <View style={{ width: width, height: height, flexDirection: 'row' }}>

                    <View style={{ width: '50%', height: '100%', alignItems: 'center', backgroundColor: colors.black, overflow: 'visible', zIndex: 1 }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -3.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={item.aboutWho} gradientSize={width / 10} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={item.aboutWhoDesc} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '76%', opacity: -5 + (scrollPosition / 100) }} />




                    </View>


                    <View style={{ width: '50%', height: '100%', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', backgroundColor: colors.black, overflow: 'visible', zIndex: 1 }}>






                        <Animated.Image style={{ width: 350, height: 500, position: 'absolute', opacity: -3.8 + (scrollPosition / 100), borderRadius: 30 }} source={{ uri: item.secondImage }} />


                    </View>


                </View>}

                {width < 650 && <View style={{ width: width, height: height, paddingTop: 50 }}>




                    <View style={{ width: '100%', height: '50%', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', overflow: 'visible', zIndex: 1 }}>



                        <Animated.View style={{ width: 200, height: 300, opacity: - (3 + (item.lines.length / 2)) + (scrollPosition / 100), zIndex: 1, alignItems: 'center', borderRadius: 30, transform: (scrollPosition / 100) > 3.5 + (item.lines.length / 2) ? [{ rotateY: (scrollPosition / 5 - ((350 + ((item.lines.length / 2) * 100)) / 5) + 'deg') }, { rotateX: (scrollPosition / 10 - ((350 + ((item.lines.length / 2) * 100)) / 10) + 'deg') }] : [] }}>



                            <Animated.Image style={{ width: 200, height: 300, position: 'absolute', borderRadius: 30 }} source={{ uri: item.secondImage }} />
                        </Animated.View>


                    </View>

                    <View style={{ width: '100%', height: '50%', alignItems: 'center', overflow: 'visible', zIndex: 1, paddingTop: 20 }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: - (4 + (item.lines.length / 2)) + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={item.aboutWho} gradientSize={width / 10} gradient={true} />
                        </Animated.View>

                        <AppText text={item.aboutWhoDesc} style={{ fontSize: 20, color: colors.white, width: '76%', opacity: - (4.5 + (item.lines.length / 2)) + (scrollPosition / 100) }} />




                    </View>

                </View>}

            </View>
        </ScrollView >
    );
}

export default FullSong;