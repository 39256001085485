import React, { useEffect, useRef, useState } from 'react';
import { View, Dimensions, Animated, FlatList, Image, TouchableOpacity, ScrollView } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import YouTube from 'react-youtube';
import Lottie from "lottie-react";

import AppText from '../components/AppText';
import colors from '../colors/colors';


const height = window.innerHeight;
const width = window.innerWidth;

function TrophiesScreen({ navigation }) {

    const [scrollPosition, setScrollPosition] = useState(0);

    const scrollTextOpacity = useState(new Animated.Value(0))[0];

    const TrophiesTextOpacity = useState(new Animated.Value(0))[0];

    const exitOpacity = useState(new Animated.Value(0))[0];

    const opts = {
        height: '400',
        width: '600',
    };

    const optsMobile = {
        height: '250',
        width: '350',
    };



    const toogleOpacities = () => {

        Animated.timing(TrophiesTextOpacity, {
            toValue: 1,
            duration: 800,
            useNativeDriver: true,
        }).start();

        setTimeout(function () {
            Animated.timing(scrollTextOpacity, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 500)

        setTimeout(function () {
            Animated.timing(exitOpacity, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 800)

    };

    useEffect(() => {

        toogleOpacities();

    }, [])

    return (
        <ScrollView onScroll={(event) => { setScrollPosition(event.nativeEvent.contentOffset.y) }} scrollEventThrottle={16} style={{ backgroundColor: colors.black }}>

            {width > 650 && <Animated.View style={{ width: 50, height: 50, alignItems: 'center', zIndex: 1, opacity: exitOpacity, justifyContent: 'center', position: 'absolute', top: 50, left: 50 }}>


                <TouchableOpacity onPress={() => navigation.goBack()}>

                    <LinearGradient
                        colors={['#FF5733', '#A83279']}
                        style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                    >

                        <MaterialCommunityIcons name='chevron-left' size={30} color={colors.white} />


                    </LinearGradient>

                </TouchableOpacity>

            </Animated.View>}

            {width < 650 && <Animated.View style={{ width: 30, height: 30, alignItems: 'center', zIndex: 1, opacity: exitOpacity, justifyContent: 'center', position: 'absolute', top: 20, left: 20 }}>


                <TouchableOpacity onPress={() => navigation.goBack()}>

                    <LinearGradient
                        colors={['#FF5733', '#A83279']}
                        style={{ height: 30, width: 30, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                    >

                        <MaterialCommunityIcons name='chevron-left' size={25} color={colors.white} />


                    </LinearGradient>

                </TouchableOpacity>

            </Animated.View>}

            <View style={{ width: width, height: height, alignItems: 'center' }}>

                <View style={{ width: width, height: height, alignItems: 'center', justifyContent: 'center' }}>

                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: TrophiesTextOpacity, justifyContent: 'center' }}>
                        <AppText text={"TROPHIES"} gradientSize={width > 650 ? width / 8 : 80} gradient={true} />
                    </Animated.View>


                    <AppText text={"Scroll down to see the trophy gallery"} style={{ fontSize: 16, color: colors.white, width: '76%', opacity: scrollTextOpacity }} />

                </View>

                {width > 650 && <View style={{ width: width, alignItems: 'center' }}>

                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -2 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"CHAMPIONS LEAGUE"} gradientSize={width > 650 ? width / 10 : 80} gradient={true} />
                    </Animated.View>

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -3 + (scrollPosition / 100), justifyContent: 'center', flexDirection: 'row' }}>
                        <AppText text={"1977"} gradientSize={40} gradient={true} />

                        <AppText text={"1978"} gradientSize={40} gradient={true} />

                        <AppText text={"1981"} gradientSize={40} gradient={true} />

                        <AppText text={"1984"} gradientSize={40} gradient={true} />

                        <AppText text={"2005"} gradientSize={40} gradient={true} />

                        <AppText text={"2019"} gradientSize={40} gradient={true} />

                    </Animated.View>


                </View>}

                {width < 650 && <View style={{ width: width, alignItems: 'center' }}>

                    <Animated.View style={{ width: '95%', alignItems: 'center', opacity: -2 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"CHAMPIONS LEAGUE"} gradientSize={60} gradient={true} />
                    </Animated.View>

                    <Animated.View style={{ width: '80%', alignItems: 'center', marginTop: 20, opacity: -3 + (scrollPosition / 100), justifyContent: 'center', flexDirection: 'row' }}>
                        <AppText text={"1977"} gradientSize={20} gradient={true} />

                        <AppText text={"1978"} gradientSize={20} gradient={true} />

                        <AppText text={"1981"} gradientSize={20} gradient={true} />

                        <AppText text={"1984"} gradientSize={20} gradient={true} />

                        <AppText text={"2005"} gradientSize={20} gradient={true} />

                        <AppText text={"2019"} gradientSize={20} gradient={true} />

                    </Animated.View>


                </View>}


                <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'center', opacity: -4 + (scrollPosition / 100), marginTop: 30 }}>

                    <TouchableOpacity style={{}}>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 25, width: 25, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                        >


                        </LinearGradient>

                    </TouchableOpacity>

                </Animated.View>

                <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'center', opacity: -4.5 + (scrollPosition / 100) }}>

                    <TouchableOpacity style={{}}>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 25, width: 25, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                        >


                        </LinearGradient>

                    </TouchableOpacity>

                </Animated.View>

                <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'center', opacity: -5 + (scrollPosition / 100) }}>

                    <TouchableOpacity style={{}}>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 25, width: 25, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                        >


                        </LinearGradient>

                    </TouchableOpacity>

                </Animated.View>

                {width > 650 && <Animated.View style={{ width: '40%', alignItems: 'center', opacity: -6 + (scrollPosition / 100), justifyContent: 'center' }}>
                    <AppText text={"TITLES"} gradientSize={80} gradient={true} />
                </Animated.View>}

                {width < 650 && <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -6 + (scrollPosition / 100), justifyContent: 'center' }}>
                    <AppText text={"TITLES"} gradientSize={60} gradient={true} />
                </Animated.View>}


                <AppText text={"Liverpool have won the Champions League 6 times. That's twice as much as the second team in England. The most recent triumph was in 2019 when Divock Origi secured our victory against tottenham."} style={{ fontSize: 16, color: colors.white, width: '50%', opacity: -7 + (scrollPosition / 100) }} />

                {width > 650 && <View style={{ width: width, marginTop: 100, flexDirection: 'row' }}>


                    <View style={{ width: '50%', height: '100%', alignItems: 'center' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -9 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"LIV 2 - 0 TOT"} gradientSize={100} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Watch the goal that gave Liverpool their 6th Champions League"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '80%', opacity: -9.5 + (scrollPosition / 100) }} />

                        <Animated.View style={{ width: '80%', height: 250, marginVertical: 20, opacity: - 10 + (scrollPosition / 100), zIndex: 1, alignItems: 'flex-start', borderRadius: 30, transform: (scrollPosition / 100) > 12 ? ((scrollPosition / 100) < 14 ? [{ rotateY: (scrollPosition / 10 - 120 + 'deg') }, { rotateX: (scrollPosition / 10 - 120 + 'deg') }] : [{ rotateY: (20 + 'deg') }, { rotateX: (20 + 'deg') }]) : [] }}>
                            <Animated.Image style={{ width: 250, height: 350, borderRadius: 30 }} source={{ uri: "https://i.guim.co.uk/img/media/dfc6301050e2e292720ba85b54dbaaed8e9d31b4/0_56_5568_3341/master/5568.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=88f9725390a433b3ac0dacccf999dd68" }} />
                        </Animated.View>
                    </View>

                    <View style={{ width: '50%', height: '100%', alignItems: 'center' }}>

                        <Animated.View style={{ alignItems: 'center', overflow: 'visible', opacity: -10 + (scrollPosition / 100), marginVertical: 100 }}>
                            <YouTube videoId={'SMkYkVGpx-c'} opts={opts} />
                        </Animated.View>

                    </View>



                </View>}

                {width < 650 && <View style={{ width: width, marginTop: 50 }}>


                    <View style={{ width: '100%', alignItems: 'center' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -9 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"LIV 2 - 0 TOT"} gradientSize={60} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Watch the goal that gave Liverpool their 6th Champions League"} style={{ fontSize: 16, color: colors.white, textAlign: 'left', width: '80%', opacity: -9.5 + (scrollPosition / 100) }} />

                        <Animated.View style={{ width: '80%', height: 300, marginVertical: 20, opacity: - 10 + (scrollPosition / 100), zIndex: 1, alignItems: 'flex-start', borderRadius: 30, transform: (scrollPosition / 100) > 12 ? ((scrollPosition / 100) < 14 ? [{ rotateY: (scrollPosition / 10 - 120 + 'deg') }, { rotateX: (scrollPosition / 10 - 120 + 'deg') }] : [{ rotateY: (20 + 'deg') }, { rotateX: (20 + 'deg') }]) : [] }}>
                            <Animated.Image style={{ width: 200, height: 300, borderRadius: 30 }} source={{ uri: "https://i.guim.co.uk/img/media/dfc6301050e2e292720ba85b54dbaaed8e9d31b4/0_56_5568_3341/master/5568.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=88f9725390a433b3ac0dacccf999dd68" }} />
                        </Animated.View>
                    </View>

                    <View style={{ width: '100%', marginTop: 50, alignItems: 'center' }}>

                        <Animated.View style={{ alignItems: 'center', overflow: 'visible', opacity: -15 + (scrollPosition / 100), marginVertical: 10, justifyContent: 'center' }}>
                            <Lottie animationData={require("../assets/animations/loading.json")} loop={true} style={{ width: 100, height: 100, position: 'absolute' }} />
                            <YouTube videoId={'SMkYkVGpx-c'} opts={optsMobile} style={{ zIndex: 1 }} />
                        </Animated.View>

                    </View>



                </View>}

                {width > 650 && <View style={{ width: width, alignItems: 'center', marginTop: 10 }}>

                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -15 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"PREMIER LEAGUE"} gradientSize={width > 650 ? width / 10 : 80} gradient={true} />
                    </Animated.View>

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -17 + (scrollPosition / 100), justifyContent: 'center', flexDirection: 'row' }}>
                        <AppText text={"19 TIMES"} gradientSize={40} gradient={true} />

                    </Animated.View>


                    <Animated.View style={{ width: '40%', height: 80, opacity: -19 + (scrollPosition / 100), justifyContent: 'center', marginTop: 100 }}>

                        <View style={{ position: 'absolute', left: 0 }}>
                            <AppText text={"LIV"} gradientSize={60} gradient={true} left={true} />
                        </View>

                        <View style={{ position: 'absolute', right: 0 }}>
                            <AppText text={"99"} gradientSize={60} gradient={true} left={true} />
                        </View>

                        <View style={{ position: 'absolute', bottom: 0, width: '100%', height: 3, backgroundColor: colors.white }}></View>

                    </Animated.View>

                    <Animated.View style={{ width: '40%', height: 80, opacity: -20 + (scrollPosition / 100), justifyContent: 'center' }}>

                        <View style={{ position: 'absolute', left: 0 }}>
                            <AppText text={"MCI"} gradientSize={60} gradient={true} left={true} />
                        </View>

                        <View style={{ position: 'absolute', right: 0 }}>
                            <AppText text={"81"} gradientSize={60} gradient={true} left={true} />
                        </View>

                        <View style={{ position: 'absolute', bottom: 0, width: '100%', height: 3, backgroundColor: colors.white }}></View>

                    </Animated.View>

                    <Animated.View style={{ width: '40%', height: 80, opacity: -21 + (scrollPosition / 100), justifyContent: 'center' }}>

                        <View style={{ position: 'absolute', left: 0 }}>
                            <AppText text={"MNU"} gradientSize={60} gradient={true} left={true} />
                        </View>

                        <View style={{ position: 'absolute', right: 0 }}>
                            <AppText text={"66"} gradientSize={60} gradient={true} left={true} />
                        </View>

                        <View style={{ position: 'absolute', bottom: 0, width: '100%', height: 3, backgroundColor: colors.white }}></View>

                    </Animated.View>

                    <Animated.View style={{ width: '40%', marginTop: 50, alignItems: 'center', opacity: -22 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"2020"} gradientSize={100} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={"Liverpool won their last Premier League in 2020 under Jurgen Klopp. It was a season in which Liverpool absolutely dominated the Premier League, winning 32 games and achieving 99 points. In the first 27 matches, Liverpool won 26!"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '40%', opacity: -23 + (scrollPosition / 100) }} />



                </View>}

                {width < 650 && <View style={{ width: width, alignItems: 'center', marginTop: 10 }}>

                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -17.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"PREMIER LEAGUE"} gradientSize={width > 650 ? width / 10 : 80} gradient={true} />
                    </Animated.View>

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -19 + (scrollPosition / 100), justifyContent: 'center', flexDirection: 'row' }}>
                        <AppText text={"19 TIMES"} style={{ fontSize: 20, marginTop: 20, color: colors.white }} />

                    </Animated.View>


                    <Animated.View style={{ width: '80%', height: 80, opacity: -21 + (scrollPosition / 100), justifyContent: 'center', marginTop: 100 }}>

                        <View style={{ position: 'absolute', left: 0 }}>
                            <AppText text={"LIV"} gradientSize={60} gradient={true} left={true} />
                        </View>

                        <View style={{ position: 'absolute', right: 0 }}>
                            <AppText text={"99"} gradientSize={60} gradient={true} left={true} />
                        </View>

                        <View style={{ position: 'absolute', bottom: 0, width: '100%', height: 3, backgroundColor: colors.white }}></View>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', height: 80, opacity: -22 + (scrollPosition / 100), justifyContent: 'center' }}>

                        <View style={{ position: 'absolute', left: 0 }}>
                            <AppText text={"MCI"} gradientSize={60} gradient={true} left={true} />
                        </View>

                        <View style={{ position: 'absolute', right: 0 }}>
                            <AppText text={"81"} gradientSize={60} gradient={true} left={true} />
                        </View>

                        <View style={{ position: 'absolute', bottom: 0, width: '100%', height: 3, backgroundColor: colors.white }}></View>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', height: 80, opacity: -23 + (scrollPosition / 100), justifyContent: 'center' }}>

                        <View style={{ position: 'absolute', left: 0 }}>
                            <AppText text={"MNU"} gradientSize={60} gradient={true} left={true} />
                        </View>

                        <View style={{ position: 'absolute', right: 0 }}>
                            <AppText text={"66"} gradientSize={60} gradient={true} left={true} />
                        </View>

                        <View style={{ position: 'absolute', bottom: 0, width: '100%', height: 3, backgroundColor: colors.white }}></View>

                    </Animated.View>

                    <Animated.View style={{ width: '80%', marginTop: 50, alignItems: 'center', opacity: -24 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"2020"} gradientSize={80} gradient={true} left={true} />
                    </Animated.View>

                    <AppText text={"Liverpool won their last Premier League in 2020 under Jurgen Klopp. It was a season in which Liverpool absolutely dominated the Premier League, winning 32 games and achieving 99 points. In the first 27 matches, Liverpool won 26!"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '80%', opacity: -25 + (scrollPosition / 100) }} />



                </View>}


                {width > 650 && <View style={{ width: width, marginTop: 100, flexDirection: 'row' }}>


                    <View style={{ width: '50%', height: '100%', alignItems: 'center' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -25 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"LIV 5 - 3 CHE"} gradientSize={100} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Watch the Match that gave Liverpool their 19th Premier League and the trophy lift!"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '80%', opacity: -26 + (scrollPosition / 100) }} />

                        <Animated.View style={{ width: '80%', height: 250, marginVertical: 20, opacity: - 27 + (scrollPosition / 100), zIndex: 1, alignItems: 'flex-start', borderRadius: 30, transform: (scrollPosition / 100) > 27 ? ((scrollPosition / 100) < 29 ? [{ rotateY: (scrollPosition / 10 - 270 + 'deg') }, { rotateX: (scrollPosition / 10 - 270 + 'deg') }] : [{ rotateY: (20 + 'deg') }, { rotateX: (20 + 'deg') }]) : [] }}>
                            <Animated.Image style={{ width: 250, height: 350, borderRadius: 30 }} source={{ uri: "https://e0.365dm.com/20/07/1600x900/skysports-henderson-liverpool_5047014.jpg?20200723001128" }} />
                        </Animated.View>
                    </View>

                    <View style={{ width: '50%', height: '100%', alignItems: 'center' }}>

                        <Animated.View style={{ alignItems: 'center', overflow: 'visible', opacity: -26.5 + (scrollPosition / 100), marginVertical: 100 }}>
                            <YouTube videoId={'05TKv-iRMwk'} opts={opts} />
                        </Animated.View>

                    </View>



                </View>}

                {width < 650 && <View style={{ width: width, marginTop: 50 }}>


                    <View style={{ width: '100%', alignItems: 'center' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -27.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"LIV 5 - 3 CHE"} gradientSize={60} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Watch the Match that gave Liverpool their 19th Premier League and the trophy lift!"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '80%', opacity: -28.5 + (scrollPosition / 100) }} />

                        <Animated.View style={{ width: '80%', height: 300, marginVertical: 20, opacity: - 29 + (scrollPosition / 100), zIndex: 1, alignItems: 'flex-start', borderRadius: 30, transform: (scrollPosition / 100) > 30 ? ((scrollPosition / 100) < 32 ? [{ rotateY: (scrollPosition / 10 - 300 + 'deg') }, { rotateX: (scrollPosition / 10 - 300 + 'deg') }] : [{ rotateY: (20 + 'deg') }, { rotateX: (20 + 'deg') }]) : [] }}>
                            <Animated.Image style={{ width: 200, height: 300, borderRadius: 30 }} source={{ uri: "https://e0.365dm.com/20/07/1600x900/skysports-henderson-liverpool_5047014.jpg?20200723001128" }} />
                        </Animated.View>
                    </View>

                    <View style={{ width: '100%', marginTop: 30, alignItems: 'center' }}>

                        <Animated.View style={{ alignItems: 'center', overflow: 'visible', opacity: -33.5 + (scrollPosition / 100), marginVertical: 20, justifyContent: 'center' }}>
                            <Lottie animationData={require("../assets/animations/loading.json")} loop={true} style={{ width: 100, height: 100, position: 'absolute' }} />
                            <YouTube videoId={'05TKv-iRMwk'} opts={optsMobile} style={{ zIndex: 1 }} />
                        </Animated.View>

                    </View>



                </View>}

                {width > 650 && <View style={{ width: width, marginTop: 50, alignItems: 'center' }}>

                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -33 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"FA CUP"} gradientSize={width > 650 ? width / 8 : 80} gradient={true} />
                    </Animated.View>

                    <AppText text={"Liverpool won the FA Cup 8 times. The last time was in 2022 when the Reds defeated Chelsea on penalties 6-5."} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '40%', opacity: -35 + (scrollPosition / 100) }} />

                </View>}

                {width < 650 && <View style={{ width: width, marginTop: 50, alignItems: 'center' }}>

                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -36 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"FA CUP"} gradientSize={width > 650 ? width / 8 : 80} gradient={true} />
                    </Animated.View>

                    <AppText text={"Liverpool won the FA Cup 8 times. The last time was in 2022 when the Reds defeated Chelsea on penalties 6-5."} style={{ fontSize: 16, color: colors.white, textAlign: 'left', width: '80%', opacity: -37 + (scrollPosition / 100) }} />

                </View>}

                {width > 650 && <View style={{ width: width, marginTop: 100, flexDirection: 'row' }}>


                    <View style={{ width: '50%', height: '100%', alignItems: 'center' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -37 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"LIV 6 - 5 CHE"} gradientSize={100} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Watch the excellent penalty shootout that gave Liverpool FC thier 8th Tittle."} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '80%', opacity: -38 + (scrollPosition / 100) }} />

                        <Animated.View style={{ width: '80%', height: 250, marginVertical: 20, opacity: - 39 + (scrollPosition / 100), zIndex: 1, alignItems: 'flex-start', borderRadius: 30, transform: (scrollPosition / 100) > 40 ? ((scrollPosition / 100) < 42 ? [{ rotateY: (scrollPosition / 10 - 400 + 'deg') }, { rotateX: (scrollPosition / 10 - 400 + 'deg') }] : [{ rotateY: (20 + 'deg') }, { rotateX: (20 + 'deg') }]) : [] }}>
                            <Animated.Image style={{ width: 250, height: 350, borderRadius: 30 }} source={{ uri: "https://backend.liverpoolfc.com/sites/default/files/styles/xl/public/acquiadam/2022-05/Jordan-Henderson-trophy-lift-gallery-14052022.png?itok=xxKfbr-j" }} />
                        </Animated.View>
                    </View>

                    <View style={{ width: '50%', height: '100%', alignItems: 'center' }}>

                        <Animated.View style={{ alignItems: 'center', overflow: 'visible', opacity: -38.5 + (scrollPosition / 100), marginVertical: 100 }}>
                            <YouTube videoId={'IK6ky_jbBqI'} opts={opts} />
                        </Animated.View>

                    </View>



                </View>}

                {width < 650 && <View style={{ width: width, marginTop: 50 }}>


                    <View style={{ width: '100%', alignItems: 'center' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -38 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"LIV 6 - 5 CHE"} gradientSize={60} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Watch the excellent penalty shootout that gave Liverpool FC thier 8th Tittle."} style={{ fontSize: 16, color: colors.white, textAlign: 'left', width: '80%', opacity: -38.5 + (scrollPosition / 100) }} />

                        <Animated.View style={{ width: '80%', height: 300, marginVertical: 20, opacity: - 39 + (scrollPosition / 100), zIndex: 1, alignItems: 'flex-start', borderRadius: 30, transform: (scrollPosition / 100) > 40 ? ((scrollPosition / 100) < 42 ? [{ rotateY: (scrollPosition / 10 - 400 + 'deg') }, { rotateX: (scrollPosition / 10 - 400 + 'deg') }] : [{ rotateY: (20 + 'deg') }, { rotateX: (20 + 'deg') }]) : [] }}>
                            <Animated.Image style={{ width: 200, height: 300, borderRadius: 30 }} source={{ uri: "https://backend.liverpoolfc.com/sites/default/files/styles/xl/public/acquiadam/2022-05/Jordan-Henderson-trophy-lift-gallery-14052022.png?itok=xxKfbr-j" }} />
                        </Animated.View>
                    </View>

                    <View style={{ width: '100%', marginTop: 30, alignItems: 'center' }}>

                        <Animated.View style={{ alignItems: 'center', overflow: 'visible', opacity: -43.5 + (scrollPosition / 100), marginVertical: 30, justifyContent: 'center' }}>
                            <Lottie animationData={require("../assets/animations/loading.json")} loop={true} style={{ width: 100, height: 100, position: 'absolute' }} />
                            <YouTube videoId={'IK6ky_jbBqI'} opts={optsMobile} style={{ zIndex: 1 }} />
                        </Animated.View>

                    </View>



                </View>}

                {width > 650 && <View style={{ width: width, marginTop: 50, alignItems: 'center' }}>

                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -44 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"CARABAO CUP"} gradientSize={width > 650 ? width / 10 : 80} gradient={true} />
                    </Animated.View>

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -45 + (scrollPosition / 100), justifyContent: 'center', flexDirection: 'row' }}>
                        <AppText text={"9 TIMES"} gradientSize={40} gradient={true} />
                    </Animated.View>

                    <Animated.View style={{ width: '50%', height: 450, marginVertical: 20, opacity: - 47 + (scrollPosition / 100), zIndex: 1, alignItems: 'flex-start', borderRadius: 30, transform: (scrollPosition / 100) > 48 ? ((scrollPosition / 100) < 50 ? [{ rotateX: (scrollPosition / 5 - 960 + 'deg') }] : [{ rotateX: (40 + 'deg') }]) : [] }}>
                        <Animated.Image style={{ width: '100%', height: 450, borderRadius: 30 }} source={{ uri: "https://a.espncdn.com/combiner/i?img=/photo/2022/0227/r979936_1296x729_16-9.jpg&cquality=80&h=370&w=655" }} />
                    </Animated.View>

                    <AppText text={"The last time Liverpool FC won the Carabao Cup was on February 27, 2022. It was in amazing circumstances as the Reds won the title after a thrilling penalty shootout winning 11-10!"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '40%', marginTop: 20, opacity: -52 + (scrollPosition / 100) }} />

                </View>}

                {width < 650 && <View style={{ width: width, marginTop: 50, alignItems: 'center' }}>

                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -47 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"CARABAO CUP"} gradientSize={width > 650 ? width / 10 : 60} gradient={true} />
                    </Animated.View>

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -47.5 + (scrollPosition / 100), justifyContent: 'center', flexDirection: 'row' }}>
                        <AppText text={"9 TIMES"} style={{ fontSize: 20, color: colors.white, marginTop: 20 }} />
                    </Animated.View>

                    <Animated.View style={{ width: '90%', height: 250, marginVertical: 20, opacity: - 48 + (scrollPosition / 100), zIndex: 1, alignItems: 'flex-start', borderRadius: 30, transform: (scrollPosition / 100) > 49 ? ((scrollPosition / 100) < 51 ? [{ rotateX: (scrollPosition / 7 - 700 + 'deg') }] : [{ rotateX: (28.57 + 'deg') }]) : [] }}>
                        <Animated.Image style={{ width: '100%', height: 250, borderRadius: 30 }} source={{ uri: "https://a.espncdn.com/combiner/i?img=/photo/2022/0227/r979936_1296x729_16-9.jpg&cquality=80&h=370&w=655" }} />
                    </Animated.View>

                    <AppText text={"The last time Liverpool FC won the Carabao Cup was on February 27, 2022. It was in amazing circumstances as the Reds won the title after a thrilling penalty shootout winning 11-10!"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '80%', marginTop: 10, opacity: -51.5 + (scrollPosition / 100) }} />

                </View>}

                {width > 650 && <View style={{ width: width, marginTop: 80, flexDirection: 'row' }}>


                    <View style={{ width: '50%', height: '100%', alignItems: 'center' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -54 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"LIV 11 - 10 CHE"} gradientSize={100} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Watch the amazing penatly shootout that have Liverpool their 9th Carabao Cup Title."} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '80%', opacity: -55 + (scrollPosition / 100) }} />

                        <Animated.View style={{ width: '80%', height: 250, marginVertical: 20, opacity: - 57 + (scrollPosition / 100), zIndex: 1, alignItems: 'flex-start', borderRadius: 30 }}>
                            <Animated.Image style={{ width: 250, height: 350, borderRadius: 30 }} source={{ uri: "https://cdn.vox-cdn.com/thumbor/bFiDIEmD3quvbdJtqOX1d5IEr1w=/0x0:5094x3396/1200x800/filters:focal(2133x1067:2947x1881)/cdn.vox-cdn.com/uploads/chorus_image/image/70557584/1373163877.0.jpg" }} />
                        </Animated.View>
                    </View>

                    <View style={{ width: '50%', height: '100%', alignItems: 'center' }}>

                        <Animated.View style={{ alignItems: 'center', overflow: 'visible', opacity: -56.6 + (scrollPosition / 100), marginVertical: 100 }}>
                            <YouTube videoId={'DWU4FYgGOaI'} opts={opts} />
                        </Animated.View>

                    </View>



                </View>}

                {width < 650 && <View style={{ width: width, marginTop: 40 }}>


                    <View style={{ width: '100%', alignItems: 'center' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -53.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"LIV 11 - 10 CHE"} gradientSize={60} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Watch the amazing penatly shootout that have Liverpool their 9th Carabao Cup Title."} style={{ fontSize: 16, color: colors.white, textAlign: 'left', width: '80%', opacity: -54.5 + (scrollPosition / 100) }} />

                        <Animated.View style={{ width: '80%', height: 200, marginVertical: 20, opacity: - 55 + (scrollPosition / 100), zIndex: 1, alignItems: 'flex-start', borderRadius: 30, transform: (scrollPosition / 100) > 56 ? ((scrollPosition / 100) < 58 ? [{ rotateY: (scrollPosition / 10 - 560 + 'deg') }, { rotateX: (scrollPosition / 10 - 560 + 'deg') }] : [{ rotateY: (20 + 'deg') }, { rotateX: (20 + 'deg') }]) : [] }}>
                            <Animated.Image style={{ width: 200, height: 300, borderRadius: 30 }} source={{ uri: "https://cdn.vox-cdn.com/thumbor/bFiDIEmD3quvbdJtqOX1d5IEr1w=/0x0:5094x3396/1200x800/filters:focal(2133x1067:2947x1881)/cdn.vox-cdn.com/uploads/chorus_image/image/70557584/1373163877.0.jpg" }} />
                        </Animated.View>
                    </View>

                    <View style={{ width: '100%', height: 400, marginTop: 100, alignItems: 'center' }}>

                        <Animated.View style={{ alignItems: 'center', overflow: 'visible', opacity: -59.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <Lottie animationData={require("../assets/animations/loading.json")} loop={true} style={{ width: 100, height: 100, position: 'absolute' }} />
                            <YouTube videoId={'DWU4FYgGOaI'} opts={optsMobile} style={{ zIndex: 1 }} />
                        </Animated.View>

                    </View>



                </View>}

                {width > 650 && <View style={{ width: 200, height: 100 }}>

                </View>}

                {width < 650 && <View style={{ width: 200, height: 50 }}>

                </View>}

            </View>
        </ScrollView>
    );
}

export default TrophiesScreen;