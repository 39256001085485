import React, { useEffect, useRef, useState } from 'react';
import { View, Dimensions, Animated, FlatList, Image, TouchableOpacity, ScrollView } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import YouTube from 'react-youtube';

import AppText from '../components/AppText';
import colors from '../colors/colors';


const height = window.innerHeight;
const width = window.innerWidth;


function StatsScreen({ navigation }) {

    const [scrollPosition, setScrollPosition] = useState(0);

    const scrollTextOpacity = useState(new Animated.Value(0))[0];

    const statsTextOpacity = useState(new Animated.Value(0))[0];

    const exitOpacity = useState(new Animated.Value(0))[0];

    const toogleOpacities = () => {

        Animated.timing(statsTextOpacity, {
            toValue: 1,
            duration: 800,
            useNativeDriver: true,
        }).start();

        setTimeout(function () {
            Animated.timing(scrollTextOpacity, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 500)

        setTimeout(function () {
            Animated.timing(exitOpacity, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 800)

    };

    useEffect(() => {

        toogleOpacities();

    }, [])

    return (
        <ScrollView onScroll={(event) => { setScrollPosition(event.nativeEvent.contentOffset.y) }} scrollEventThrottle={16} style={{ backgroundColor: colors.black }}>


            {width > 650 && <Animated.View style={{ width: 50, height: 50, alignItems: 'center', zIndex: 1, opacity: exitOpacity, justifyContent: 'center', position: 'absolute', top: 50, left: 50 }}>


                <TouchableOpacity onPress={() => navigation.goBack()}>

                    <LinearGradient
                        colors={['#FF5733', '#A83279']}
                        style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                    >

                        <MaterialCommunityIcons name='chevron-left' size={30} color={colors.white} />


                    </LinearGradient>

                </TouchableOpacity>

            </Animated.View>}

            {width < 650 && <Animated.View style={{ width: 30, height: 30, alignItems: 'center', zIndex: 1, opacity: exitOpacity, justifyContent: 'center', position: 'absolute', top: 20, left: 20 }}>


                <TouchableOpacity onPress={() => navigation.goBack()}>

                    <LinearGradient
                        colors={['#FF5733', '#A83279']}
                        style={{ height: 30, width: 30, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                    >

                        <MaterialCommunityIcons name='chevron-left' size={25} color={colors.white} />


                    </LinearGradient>

                </TouchableOpacity>

            </Animated.View>}


            <View style={{ width: width, height: height, alignItems: 'center' }}>

                <View style={{ width: width, height: height, alignItems: 'center', justifyContent: 'center' }}>

                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: statsTextOpacity, justifyContent: 'center' }}>
                        <AppText text={"STATISTICS"} gradientSize={width > 650 ? width / 8 : 70} gradient={true} />
                    </Animated.View>


                    <AppText text={"Scroll down for some Liverpool FC Stats"} style={{ fontSize: 16, color: colors.white, width: '76%', opacity: scrollTextOpacity }} />

                </View>

                {width > 650 && <View style={{ width: width, height: height, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>



                    <View style={{ width: '50%', height: '100%', alignItems: 'center', overflow: 'visible' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -3.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"ALL TIME"} gradientSize={width / 10} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Liverpool is first in the all time english football league with 7115 points!"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '76%', opacity: -4 + (scrollPosition / 100) }} />




                    </View>

                    <View style={{ width: '50%', height: '100%', alignItems: 'center', justifyContent: 'center' }}>


                        <Animated.View style={{ width: '70%', height: 80, opacity: -5 + (scrollPosition / 100), justifyContent: 'center' }}>

                            <View style={{ position: 'absolute', left: 0 }}>
                                <AppText text={"LIV"} gradientSize={60} gradient={true} left={true} />
                            </View>

                            <View style={{ position: 'absolute', right: 0 }}>
                                <AppText text={"7115"} gradientSize={60} gradient={true} left={true} />
                            </View>

                            <View style={{ position: 'absolute', bottom: 0, width: '100%', height: 3, backgroundColor: colors.white }}></View>

                        </Animated.View>

                        <Animated.View style={{ width: '70%', height: 80, opacity: -5.3 + (scrollPosition / 100), justifyContent: 'center' }}>

                            <View style={{ position: 'absolute', left: 0 }}>
                                <AppText text={"ARS"} gradientSize={60} gradient={true} left={true} />
                            </View>

                            <View style={{ position: 'absolute', right: 0 }}>
                                <AppText text={"6932"} gradientSize={60} gradient={true} left={true} />
                            </View>

                            <View style={{ position: 'absolute', bottom: 0, width: '100%', height: 3, backgroundColor: colors.white }}></View>

                        </Animated.View>

                        <Animated.View style={{ width: '70%', height: 80, opacity: -5.6 + (scrollPosition / 100), justifyContent: 'center' }}>

                            <View style={{ position: 'absolute', left: 0 }}>
                                <AppText text={"EVE"} gradientSize={60} gradient={true} left={true} />
                            </View>

                            <View style={{ position: 'absolute', right: 0 }}>
                                <AppText text={"6807"} gradientSize={60} gradient={true} left={true} />
                            </View>

                            <View style={{ position: 'absolute', bottom: 0, width: '100%', height: 3, backgroundColor: colors.white }}></View>

                        </Animated.View>


                    </View>

                </View>}

                {width < 650 && <View style={{ width: width, alignItems: 'center', justifyContent: 'center', }}>



                    <View style={{ width: '100%', height: 100, alignItems: 'center', overflow: 'visible' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -1.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"ALL TIME"} gradientSize={width / 10} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Liverpool is first in the all time english football league with 7115 points!"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '80%', opacity: -2 + (scrollPosition / 100) }} />




                    </View>

                    <View style={{ width: '100%', marginTop: 50, alignItems: 'center', justifyContent: 'center' }}>


                        <Animated.View style={{ width: '70%', height: 80, opacity: -3 + (scrollPosition / 100), justifyContent: 'center' }}>

                            <View style={{ position: 'absolute', left: 0 }}>
                                <AppText text={"LIV"} gradientSize={30} gradient={true} left={true} />
                            </View>

                            <View style={{ position: 'absolute', right: 0 }}>
                                <AppText text={"7115"} gradientSize={30} gradient={true} left={true} />
                            </View>

                            <View style={{ position: 'absolute', bottom: 0, width: '100%', height: 3, backgroundColor: colors.white }}></View>

                        </Animated.View>

                        <Animated.View style={{ width: '70%', height: 80, opacity: -3.3 + (scrollPosition / 100), justifyContent: 'center' }}>

                            <View style={{ position: 'absolute', left: 0 }}>
                                <AppText text={"ARS"} gradientSize={30} gradient={true} left={true} />
                            </View>

                            <View style={{ position: 'absolute', right: 0 }}>
                                <AppText text={"6932"} gradientSize={30} gradient={true} left={true} />
                            </View>

                            <View style={{ position: 'absolute', bottom: 0, width: '100%', height: 3, backgroundColor: colors.white }}></View>

                        </Animated.View>

                        <Animated.View style={{ width: '70%', height: 80, opacity: -3.6 + (scrollPosition / 100), justifyContent: 'center' }}>

                            <View style={{ position: 'absolute', left: 0 }}>
                                <AppText text={"EVE"} gradientSize={30} gradient={true} left={true} />
                            </View>

                            <View style={{ position: 'absolute', right: 0 }}>
                                <AppText text={"6807"} gradientSize={30} gradient={true} left={true} />
                            </View>

                            <View style={{ position: 'absolute', bottom: 0, width: '100%', height: 3, backgroundColor: colors.white }}></View>

                        </Animated.View>


                    </View>

                </View>}

                {width > 650 && <View style={{ width: width, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>



                    <View style={{ width: '50%', height: '100%', alignItems: 'center', overflow: 'visible' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -10.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"GOALS"} gradientSize={width / 10} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Liverpool's all time top scorer is Ian Rush with 336. He scored a goal every 167 minutes and had 0.52 goal per game ratio."} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '76%', opacity: -11.5 + (scrollPosition / 100) }} />




                    </View>

                    <View style={{ width: '50%', height: '100%', flexDirection: 'row', paddingTop: 100, justifyContent: 'center' }}>


                        <Animated.View style={{ width: 200, height: 150, marginHorizontal: 10, opacity: - 12 + (scrollPosition / 100) }}>


                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 200, width: 150, justifyContent: 'center', alignItems: 'center', borderRadius: 20, zIndex: 1 }}
                            >

                                <AppText text={"0.52"} style={{ fontSize: 80, color: colors.white }} />

                            </LinearGradient>


                        </Animated.View>


                        <Animated.View style={{ width: 200, height: 150, marginHorizontal: 10, opacity: - 12.5 + (scrollPosition / 100) }}>


                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 200, width: 150, justifyContent: 'center', alignItems: 'center', borderRadius: 20, zIndex: 1 }}
                            >

                                <AppText text={"336"} style={{ fontSize: 80, color: colors.white }} />

                            </LinearGradient>


                        </Animated.View>



                        <Animated.View style={{ width: 200, height: 150, marginHorizontal: 10, opacity: - 13 + (scrollPosition / 100) }}>


                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 200, width: 150, justifyContent: 'center', alignItems: 'center', borderRadius: 20, zIndex: 1 }}
                            >

                                <AppText text={"167"} style={{ fontSize: 80, color: colors.white }} />

                            </LinearGradient>


                        </Animated.View>



                    </View>

                </View>}

                {width < 650 && <View style={{ width: width, marginTop: 100 }}>



                    <View style={{ width: '100%', alignItems: 'center', overflow: 'visible' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -4.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"GOALS"} gradientSize={width / 10} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Liverpool's all time top scorer is Ian Rush with 336. He scored a goal every 167 minutes and had 0.52 goal per game ratio."} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '80%', opacity: -5.5 + (scrollPosition / 100) }} />




                    </View>

                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', paddingTop: 40 }}>


                        <Animated.View style={{ marginHorizontal: 10, opacity: - 7 + (scrollPosition / 100) }}>


                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 120, width: 80, justifyContent: 'center', alignItems: 'center', borderRadius: 20, zIndex: 1 }}
                            >

                                <AppText text={"0.52"} style={{ fontSize: 30, color: colors.white }} />

                            </LinearGradient>


                        </Animated.View>


                        <Animated.View style={{ marginHorizontal: 10, opacity: - 7.5 + (scrollPosition / 100) }}>


                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 120, width: 80, justifyContent: 'center', alignItems: 'center', borderRadius: 20, zIndex: 1 }}
                            >

                                <AppText text={"336"} style={{ fontSize: 30, color: colors.white }} />

                            </LinearGradient>


                        </Animated.View>



                        <Animated.View style={{ marginHorizontal: 10, opacity: - 8 + (scrollPosition / 100) }}>


                            <LinearGradient
                                colors={['#FF5733', '#A83279']}
                                style={{ height: 120, width: 80, justifyContent: 'center', alignItems: 'center', borderRadius: 20, zIndex: 1 }}
                            >

                                <AppText text={"167"} style={{ fontSize: 30, color: colors.white }} />

                            </LinearGradient>


                        </Animated.View>



                    </View>

                </View>}

                {width > 650 && <View style={{ width: width, paddingTop: 40, height: height, alignItems: 'center' }}>


                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -14 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"ASSISTS"} gradientSize={width > 650 ? width / 9 : 70} gradient={true} />
                    </Animated.View>

                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -17 + (scrollPosition / 100), justifyContent: 'center', marginVertical: 20 }}>
                        <AppText text={"8"} gradientSize={width > 650 ? width / 6 : 70} gradient={true} />
                    </Animated.View>

                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -20 + (scrollPosition / 100), justifyContent: 'center', marginVertical: 20 }}>
                        <AppText text={"GERRARD"} gradientSize={40} gradient={true} />
                    </Animated.View>


                </View>}

                {width < 650 && <View style={{ width: width, paddingTop: 40, alignItems: 'center' }}>


                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -10 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"ASSISTS"} gradientSize={width > 650 ? width / 9 : 70} gradient={true} />
                    </Animated.View>

                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -12 + (scrollPosition / 100), justifyContent: 'center', marginVertical: 20 }}>
                        <AppText text={"8"} gradientSize={width > 650 ? width / 6 : 130} gradient={true} />
                    </Animated.View>

                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: - 14 + (scrollPosition / 100), justifyContent: 'center', marginVertical: 10 }}>
                        <AppText text={"GERRARD"} gradientSize={40} gradient={true} />
                    </Animated.View>

                    <AppText text={"Steven Gerrard has 92 assists for LFC. He assisted or scored every 2 games as a midfielder. A true Legend."} style={{ fontSize: 16, color: colors.white, width: '76%', opacity: - 15 + (scrollPosition / 100) }} />


                </View>}


                {width > 650 && <View style={{ width: width, paddingTop: 40, alignItems: 'center' }}>

                    <AppText text={"Steven Gerrard has 92 assists for LFC. He assisted or scored every 2 games as a midfielder. A true Legend."} style={{ fontSize: 20, color: colors.white, width: '76%', opacity: - 22 + (scrollPosition / 100) }} />


                </View>}

                {width > 650 && <View style={{ width: width, alignItems: 'center', justifyContent: 'center', flexDirection: 'row' }}>



                    <View style={{ width: '50%', height: '100%', alignItems: 'center', overflow: 'visible' }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -23.5 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"COACH"} gradientSize={width / 10} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Jurgen Klopp now works 8 years for Liverpool FC. This makes him the longest-serving manager at one club in the entire Premier League!"} style={{ fontSize: 20, color: colors.white, textAlign: 'left', width: '76%', opacity: -24.5 + (scrollPosition / 100) }} />




                    </View>

                    <View style={{ width: '50%', height: '100%', paddingTop: 100, justifyContent: 'center', alignItems: 'center' }}>


                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -25.5 + (scrollPosition / 100), marginTop: 5, zIndex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                            <AppText text={`I'M `} style={{ fontSize: 35, color: colors.white, opacity: -25.7 + (scrollPosition / 100) }} />

                            <AppText text={`IN `} style={{ fontSize: 35, color: colors.white, opacity: -25.9 + (scrollPosition / 100) }} />

                            <AppText text={`LOVE `} style={{ fontSize: 35, color: colors.white, opacity: -26.1 + (scrollPosition / 100) }} />

                            <AppText text={`HIM `} style={{ fontSize: 35, color: colors.white, opacity: -26.3 + (scrollPosition / 100) }} />
                        </Animated.View>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -25.5 + (scrollPosition / 100), marginTop: 5, zIndex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                            <AppText text={`AND `} style={{ fontSize: 35, color: colors.white, opacity: -26.5 + (scrollPosition / 100) }} />

                            <AppText text={`I `} style={{ fontSize: 35, color: colors.white, opacity: -26.7 + (scrollPosition / 100) }} />

                            <AppText text={`FEEL `} style={{ fontSize: 35, color: colors.white, opacity: -26.9 + (scrollPosition / 100) }} />

                            <AppText text={`FINE... `} style={{ fontSize: 35, color: colors.white, opacity: -27.1 + (scrollPosition / 100) }} />
                        </Animated.View>


                    </View>

                </View>}

                {width < 650 && <View style={{ width: width, alignItems: 'center', justifyContent: 'center' }}>



                    <View style={{ width: '100%', alignItems: 'center', overflow: 'visible', paddingTop: 30 }}>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -16 + (scrollPosition / 100), justifyContent: 'center' }}>
                            <AppText text={"COACH"} gradientSize={width / 8} gradient={true} left={true} />
                        </Animated.View>

                        <AppText text={"Jurgen Klopp now works 8 years for Liverpool FC. This makes him the longest-serving manager at one club in the entire Premier League!"} style={{ fontSize: 16, color: colors.white, textAlign: 'left', width: '76%', opacity: -17 + (scrollPosition / 100) }} />




                    </View>

                    <View style={{ width: '100%', marginTop: 100, justifyContent: 'center', alignItems: 'center' }}>


                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -18.5 + (scrollPosition / 100), marginTop: 5, zIndex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                            <AppText text={`I'M `} style={{ fontSize: 35, color: colors.white, opacity: -18.7 + (scrollPosition / 100) }} />

                            <AppText text={`IN `} style={{ fontSize: 35, color: colors.white, opacity: -18.9 + (scrollPosition / 100) }} />

                            <AppText text={`LOVE `} style={{ fontSize: 35, color: colors.white, opacity: -19.1 + (scrollPosition / 100) }} />

                            <AppText text={`HIM `} style={{ fontSize: 35, color: colors.white, opacity: -19.3 + (scrollPosition / 100) }} />
                        </Animated.View>

                        <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -19.5 + (scrollPosition / 100), marginTop: 5, zIndex: 1, flexDirection: 'row', justifyContent: 'center' }}>
                            <AppText text={`AND `} style={{ fontSize: 35, color: colors.white, opacity: -19.5 + (scrollPosition / 100) }} />

                            <AppText text={`I `} style={{ fontSize: 35, color: colors.white, opacity: -19.7 + (scrollPosition / 100) }} />

                            <AppText text={`FEEL `} style={{ fontSize: 35, color: colors.white, opacity: -19.9 + (scrollPosition / 100) }} />

                            <AppText text={`FINE... `} style={{ fontSize: 35, color: colors.white, opacity: -20.1 + (scrollPosition / 100) }} />
                        </Animated.View>


                    </View>

                </View>}

                {width > 650 && <View style={{ width: width, height: height + 100, alignItems: 'center' }}>


                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -28 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"FORMATION"} gradientSize={width > 650 ? width / 9 : 70} gradient={true} />
                    </Animated.View>

                    <AppText text={"Liverpool almost always start with the 433 formation. Since Jurgen Klopp has been the coach, Liverpool have used the 433 formation in around 97% of their matches!"} style={{ fontSize: 23, color: colors.white, width: '76%', opacity: - 29 + (scrollPosition / 100) }} />




                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -30 + (scrollPosition / 100), marginTop: 105, zIndex: 1, flexDirection: 'row', justifyContent: 'center' }}>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 30 }}
                        >

                            <AppText text={"7"} style={{ fontSize: 27, color: colors.white }} />


                        </LinearGradient>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 30 }}
                        >
                            <AppText text={"9"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 30 }}
                        >


                            <AppText text={"11"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>
                    </Animated.View>

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -31 + (scrollPosition / 100), marginTop: 55, zIndex: 1, flexDirection: 'row', justifyContent: 'center' }}>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 20 }}
                        >

                            <AppText text={"17"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 20 }}
                        >

                            <AppText text={"10"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 20 }}
                        >

                            <AppText text={"8"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>
                    </Animated.View>


                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -32 + (scrollPosition / 100), marginTop: 55, zIndex: 1, flexDirection: 'row', justifyContent: 'center' }}>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 20 }}
                        >

                            <AppText text={"27"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 20 }}
                        >

                            <AppText text={"4"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 20 }}
                        >

                            <AppText text={"5"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 20 }}
                        >

                            <AppText text={"66"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>
                    </Animated.View>

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -32.5 + (scrollPosition / 100), marginTop: 55, zIndex: 1, flexDirection: 'row', justifyContent: 'center' }}>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 20 }}
                        >

                            <AppText text={"1"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>


                    </Animated.View>

                </View>}

                {width < 650 && <View style={{ width: width, height: height + 300, paddingTop: 50, alignItems: 'center' }}>


                    <Animated.View style={{ width: '90%', alignItems: 'center', opacity: -21 + (scrollPosition / 100), justifyContent: 'center' }}>
                        <AppText text={"FORMATION"} gradientSize={width > 650 ? width / 9 : 70} gradient={true} />
                    </Animated.View>

                    <AppText text={"Liverpool almost always start with the 433 formation. Since Jurgen Klopp has been the coach, Liverpool have used the 433 formation in around 97% of their matches!"} style={{ fontSize: 16, color: colors.white, width: '76%', opacity: - 22 + (scrollPosition / 100) }} />




                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -23 + (scrollPosition / 100), marginTop: 105, zIndex: 1, flexDirection: 'row', justifyContent: 'center' }}>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 30 }}
                        >

                            <AppText text={"7"} style={{ fontSize: 27, color: colors.white }} />


                        </LinearGradient>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 30 }}
                        >
                            <AppText text={"9"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 30 }}
                        >


                            <AppText text={"11"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>
                    </Animated.View>

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -24 + (scrollPosition / 100), marginTop: 55, zIndex: 1, flexDirection: 'row', justifyContent: 'center' }}>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 20 }}
                        >

                            <AppText text={"17"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 20 }}
                        >

                            <AppText text={"10"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 20 }}
                        >

                            <AppText text={"8"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>
                    </Animated.View>


                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -25 + (scrollPosition / 100), marginTop: 55, zIndex: 1, flexDirection: 'row', justifyContent: 'center' }}>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 20 }}
                        >

                            <AppText text={"27"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 20 }}
                        >

                            <AppText text={"4"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 20 }}
                        >

                            <AppText text={"5"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 20 }}
                        >

                            <AppText text={"66"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>
                    </Animated.View>

                    <Animated.View style={{ width: '80%', alignItems: 'center', opacity: -26 + (scrollPosition / 100), marginTop: 55, zIndex: 1, flexDirection: 'row', justifyContent: 'center' }}>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1, marginHorizontal: 20 }}
                        >

                            <AppText text={"1"} style={{ fontSize: 27, color: colors.white }} />

                        </LinearGradient>


                    </Animated.View>

                </View>}

            </View >
        </ScrollView >
    );
}

export default StatsScreen;