import React, { useEffect, useRef, useState } from 'react';
import { View, Dimensions, Animated, FlatList, Image, TouchableOpacity } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Lottie from "lottie-react";
import YouTube from 'react-youtube';

import AppText from '../components/AppText';
import colors from '../colors/colors';

const height = window.innerHeight;
const width = window.innerWidth;

function FullVideo({ navigation, route }) {

    const item = route.params;

    const videoOpacity = useState(new Animated.Value(0))[0];

    const exitOpacity = useState(new Animated.Value(0))[0];

    const opts = {
        height: '' + height,
        width: '' + width,
    };




    const toogleOpacities = () => {

        Animated.timing(videoOpacity, {
            toValue: 1,
            duration: 800,
            useNativeDriver: true,
        }).start();

        setTimeout(function () {
            Animated.timing(exitOpacity, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 500)


    };

    useEffect(() => {

        toogleOpacities();

    }, [])

    return (
        <View style={{ width: width, height: height, backgroundColor: colors.black, justifyContent: 'center', alignItems: 'center' }}>

            {width > 650 && <Animated.View style={{ width: 50, height: 50, alignItems: 'center', zIndex: 1, opacity: exitOpacity, justifyContent: 'center', position: 'absolute', top: 50, left: 50 }}>


                <TouchableOpacity onPress={() => navigation.goBack()}>

                    <LinearGradient
                        colors={['#FF5733', '#A83279']}
                        style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                    >

                        <MaterialCommunityIcons name='chevron-left' size={30} color={colors.white} />


                    </LinearGradient>

                </TouchableOpacity>

            </Animated.View>}

            {width < 650 && <Animated.View style={{ width: 30, height: 30, alignItems: 'center', zIndex: 1, opacity: exitOpacity, justifyContent: 'center', position: 'absolute', top: 20, left: 20 }}>


                <TouchableOpacity onPress={() => navigation.goBack()}>

                    <LinearGradient
                        colors={['#FF5733', '#A83279']}
                        style={{ height: 30, width: 30, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                    >

                        <MaterialCommunityIcons name='chevron-left' size={25} color={colors.white} />


                    </LinearGradient>

                </TouchableOpacity>

            </Animated.View>}

            <Animated.View style={{ alignItems: 'center', overflow: 'visible', opacity: videoOpacity, justifyContent: 'center' }}>
                <Lottie animationData={require("../assets/animations/loading.json")} loop={true} style={{ width: 100, height: 100, position: 'absolute' }} />
                <YouTube videoId={item.videoId} opts={opts} style={{ zIndex: 1 }} />
            </Animated.View>

        </View>
    );
}

export default FullVideo;