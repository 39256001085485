import React, { useEffect, useRef, useState } from 'react';
import { View, Dimensions, Animated, FlatList, Image, TouchableOpacity, ScrollView } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Lottie from "lottie-react";

import AppText from '../components/AppText';
import colors from '../colors/colors';
import AppTextInput from '../components/AppTextInput';

const height = window.innerHeight;
const width = window.innerWidth;

const diogoJotaSong =
    `“Ohhh, he wears the number 20, He will take us to victory, And when he’s running down the left-wing, He’ll cut inside and score for LFC, He’s a lad from Portugal, Better than Figo don’t you know,Ohhh, his name is Diogo!”`

const virgilVanDijkSong =
    "“He's our centre-half, He's our number four, Watch him defend, And we watch him score, He'll pass the ball, Calm as you like, He's Virgil van Dijk, He's Virgil van Dijk”"

const moSalahSong =
    "“Mo Salah, Mo Salah, Mo Salah, running down the wing! 'Salah, la, la, la, la, la, the Egyptian King!'”"


const SiSenior =
    "“There's something that the kop wants you to know The best in the world his name is Bobby Firmino Our number nine Give him the ball and he'll score everytime Si Señor Pass the ball to Bobby and he'll score”"

const KostasTsimkasSong =
    "“Tsimi, Tsimi, Tsimi our Kostas Tsimikas, his passport says he's Greek but we all know that he's Scouse”"

const ThiahoAlacntaraSong =
    "“Thiago, Thiago Alcantara Thiago, Thiago Alcantara He moves through midfield like nobody does The first time I saw him, I knew it was love He’ll roll you like a Cuban His passes never miss Thiago Alcantara he takes the f*cking piss”"

const JurgenKloppSong =
    "“I'm in love with him and I feel fine I'm so glad that Jurgen is a Red I'm so glad he delivered what he said Jurgen said to me, you know. We'll win the Premier League, you know. He said so ”"

const AndyRobertsonSong =
    "“Oh Andy Andy,  Andy Andy, Andy Andy Robertson, Oh Andy Andy,  Andy Andy, Andy Andy Robertson,”"

const AllezAllezAllez =
    "“We conquered all of Europe, And we will never stop, From Paris on to Turkey, We've won the f*cking lot! Bob Paisley and Bill Shankly, The Fields of Anfield Road, We are loyal supporters, And We come from Liverpool!” Allez, allez, allez! x4”"

const Ynwa =
    "“When you walk through a storm  Hold your head up high And don't be afraid of the dark At the end of a storm There's a golden sky And the sweet silver song of a lark Walk on through the wind Walk on through the rain For your dreams be tossed and blown Walk on, walk on With hope in your heart And You'll never walk alone”"

const AllRoundTheFields =
    "“All 'round the fields of Anfield road Where once we watched the King Kenny play (and he could play) Steve Heighway on the wing We had dreams and songs to sing Of the glory 'round the fields of Anfield road”"

const SalahsScroing = `Salah's scoring all round us kopties singing haveing fun it's the season joy and understanding marry christmas everton`;

const TheScouserInOurTeam = `The scouser in our team the scouser in our team Trent Alexander-Arnold Trent Alexander-Arnold Trent Alexander-Arnold The scouser in our team the scouser in our team`;

const WeLoveYouLiverpool = `We Love you Liverpool We od We Love you Liverpool We od We Love you Liverpool We od Ooh Liverpool we love you`;


const songs = [


    { id: 1, lyrics: moSalahSong, name: "The Egyptian King", videoId: "EHHyLSrso-Q", aboutWho: "Mo Salah", aboutWhoDesc: `"The Egyptian King" is one of the greatest Liverpool FC Wingers and the top Premier League scorer for The Reds. `, secondImage: "https://s.hs-data.com/bilder/spieler/gross/173692.jpg", lines: [{ id: 1, text: "Mo Salah, Mo Salah" }, { id: 2, text: "Running Down The Wing" }, { id: 3, text: "Saaaalaaaaaah" }, { id: 4, text: "The Egyptian King" },], image: "https://assets.goal.com/v3/assets/bltcc7a7ffd2fbf71f5/blt56898e11bc1fa509/64e7029fb832d932750b5ba0/GOAL_-_Blank_WEB_-_Facebook_-_2023-08-24T081104.814.png?auto=webp&format=pjpg&width=3840&quality=60" },
    { id: 2, lyrics: diogoJotaSong, name: "The Number 20", videoId: "QCIOddCsewE", aboutWho: "Diogo Jota", aboutWhoDesc: `Portuguese player who plays as a striker for Liverpool FC`, lines: [{ id: 1, text: "Oh, he wears the number 20" }, { id: 2, text: "He will take us to victory," }, { id: 3, text: "And when he’s running down the left wing," }, { id: 4, text: "He’ll cut inside and score for LFC." }, { id: 5, text: "He’s a lad from Portugal," }, { id: 6, text: "Better than Figo don’t you know," }, { id: 7, text: "Oh, his name is Diogo!" }], secondImage: "https://www.coachesvoice.com/wp-content/webpc-passthru.php?src=https://www.coachesvoice.com/wp-content/uploads/2021/09/Jota-Mobile-New.jpg&nocache=1", image: "https://d3j2s6hdd6a7rg.cloudfront.net/v2/uploads/media/default/0002/35/thumb_134484_default_news_size_5.jpeg" },
    { id: 3, lyrics: virgilVanDijkSong, name: "Calm As You Like", lines: [{ id: 1, text: "He's our centre-half," }, { id: 2, text: "He's our number four," }, { id: 3, text: "Watch him defend," }, { id: 4, text: "And we watch him score," }, { id: 5, text: "He'll pass the ball," }, { id: 6, text: "Calm as you like," }, { id: 7, text: "He's Virgil van Dijk, He's Virgil van Dijk…" },], videoId: "32YktfnsoZg", aboutWho: "Virgil Van Dijk", aboutWhoDesc: `Dutch defender named as the uefa best player of the year in 2019.`, secondImage: "https://cdn.images.express.co.uk/img/dynamic/67/590x/1134928_1.jpg", image: "https://ocdn.eu/images/pulscms/NTI7MDA_/bf0a3e1a4a88a70ecc7eeb7639dfe5bb.jpg" },
    { id: 4, lyrics: AndyRobertsonSong, name: "Oh Andy", videoId: "jv6VvCryPXM", lines: [{ id: 1, text: "Oh Andy Andy" }, { id: 2, text: "Andy Andy Andy" }, { id: 3, text: "Andy Robertson" }], aboutWho: "Andrew Robertson", aboutWhoDesc: `Scottish left back for Liverpool FC`, secondImage: "https://i2-prod.liverpoolecho.co.uk/incoming/article18548333.ece/ALTERNATES/s1200c/0_GettyImages-1224884281.jpg", image: "https://images.performgroup.com/di/library/omnisport/4f/77/2_73746435.jpg?t=-1551758294" },
    { id: 5, lyrics: TheScouserInOurTeam, name: "The Scouser In Our Team", lines: [{ id: 1, text: "The Scouser In Our Team" }, { id: 2, text: "The Scouser In Our Team" }, { id: 3, text: "Trent Alexander-Arnold" }, { id: 4, text: "Trent Alexander-Arnold" }, { id: 5, text: "Trent Alexander-Arnold" }, { id: 3, text: "The Scouser In Our Team" },], videoId: "qAVHp6ZlLv0", aboutWho: "Trent Alexander-Arnold", aboutWhoDesc: `Scouse left back born in Liverpool`, secondImage: "https://transfery.info/img/photos/84990/1500xauto/trent-alexanderarnold.jpg", image: "https://zzapolowy.com/wp-content/uploads/2019/05/trent.jpg" },
    { id: 6, lyrics: AllezAllezAllez, name: "Allez Allez Allez", lines: [{ id: 1, text: "We've conquered all of Europe" }, { id: 2, text: "We're never going to stop" }, { id: 3, text: "From Paris down to Turkey" }, { id: 4, text: "Bob Paisley and Bill Shankly" }, { id: 5, text: "The Fields of Anfield Road" }, { id: 6, text: "We are loyal supporters" }, { id: 7, text: "And we come from Liverpool" }, { id: 8, text: "Allez, Allez, Allez x4" }], videoId: "LyAPtuw3T-Y", aboutWho: "Liverpool FC", aboutWhoDesc: `Allez Allez Allez is one of the most popular and famus Liverpool FC Chant`, secondImage: "https://i.pinimg.com/550x/37/84/99/378499e252464af3a2e5fa93b9be93fb.jpg", image: "https://assets.goal.com/v3/assets/bltcc7a7ffd2fbf71f5/bltc46b6b1ea2539df3/60dc28f0454f930f33f72c2a/88044f63820648b63e0967b3a2577d3b3362b000.png?auto=webp&format=pjpg&width=3840&quality=60" },
    { id: 7, lyrics: Ynwa, name: "YNWA", videoId: "9eWERM6QSys", lines: [{ id: 1, text: "When you walk through a storm" }, { text: "Hold your head up high" }, { text: "And don't be afraid of the dark" }, { text: "At the end of a storm" }, { text: "There's a golden sky" }, { text: "And the sweet silver song of a lark" }, { text: "Walk on through the wind" }, { text: "Walk on through the rain" }, { text: "For your dreams be tossed and blown" }, { text: "Walk on, walk on" }, { text: "With hope in your heart" }, { text: "And you'll never walk alone" }, { text: "You'll never walk alone" }, { text: "Walk on, walk on" }, { text: "With hope in your heart" }, { text: "And you'll never walk alone" }, { text: "You'll never walk alone" },], aboutWho: "Liverpool FC", aboutWhoDesc: `You'll Never Walk Alone is Liverpool's Athem`, secondImage: "https://cdn.wallpapersafari.com/46/86/PypLTE.jpg", image: "https://assets.goal.com/v3/assets/bltcc7a7ffd2fbf71f5/bltce0bb08e1613b47a/60dbeafe892a730f58850b61/a35ebc3bacfc437a3d372e6b98661f8952fd6e6e.jpg" },
    { id: 8, lyrics: AllRoundTheFields, name: "The Fields Of Anfield Road", lines: [{ text: "All round the Fields of Anfield Road" }, { text: "Where once we watched the King Kenny play (And could he play)" }, { text: "Stevie Heighway on the wing" }, { text: "We had dreams and songs to sing" }, { text: "Of the glory round the Fields of Anfield Road" },], videoId: "oRKqGe05CPs", aboutWho: "Liverpool FC", aboutWhoDesc: `The Fields Of Anfield Road is one of the greatest Liverpool FC's chants`, secondImage: "https://herbyzbliska.files.wordpress.com/2021/06/bill-shankly-gates-anfield-kevin-elias.jpg", image: "https://e0.365dm.com/18/04/2048x1152/skysports-liverpool-fans-premier-league_4280490.jpg" },
    { id: 9, lyrics: WeLoveYouLiverpool, name: "We Love You Liverpool", lines: [{ text: "We Love You Liverpool" }, { text: "We Do" }, { text: "We Love You Liverpool" }, { text: "We Do" }, { text: "We Love You Liverpool" }, { text: "We Do" }, { text: "Oooh Liverpool We Love You" },], videoId: "ykaq_ZTtKwg", aboutWho: "Liverpool FC", aboutWhoDesc: `We Love You Liverpool We Do`, secondImage: "https://s10.tvp.pl/images2/0/a/4/uid_0a49d5a827b62267ff852e4b3e77d6621652561458162_width_1200_play_0_pos_0_gs_0_height_678_pilkarze-liverpoolu-siegneli-po-drugie-trofeum-w-obecnym-sezonie-fot-getty-images.jpg", image: "https://ichef.bbci.co.uk/news/976/cpsprodpb/8D86/production/_113103263_gettyimages-1222924812.jpg" },
    { id: 10, lyrics: SalahsScroing, name: "Salah's Scoring", lines: [{ text: "Salah's scoing" }, { text: "All round us" }, { text: "Kopties singing" }, { text: "having fun" }, { text: "it's the season" }, { text: "love and understanding" }, { text: "Marry Christmas" }, { text: "Everton" }], videoId: "p2iFdNxqApk", aboutWho: "Liverpool FC", aboutWhoDesc: `Christmas song about Mo Salah`, secondImage: "https://i.pinimg.com/originals/95/54/d8/9554d861cbe0d39aae6ef3e25c1f549c.png", image: "https://pbs.twimg.com/media/DyLxxeMXQAAIpkz.jpg" },



]

function DetectorScreen({ navigation }) {

    const [isSearching, setIsSearching] = useState('');

    const [search, setSearch] = useState([]);

    const textOpacity = useState(new Animated.Value(0))[0];

    const searchBarOpacity = useState(new Animated.Value(0))[0];

    const exitOpacity = useState(new Animated.Value(0))[0];

    const toogleOpacities = () => {

        Animated.timing(textOpacity, {
            toValue: 1,
            duration: 800,
            useNativeDriver: true,
        }).start();

        setTimeout(function () {
            Animated.timing(searchBarOpacity, {
                toValue: 0.8,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 500)

        setTimeout(function () {
            Animated.timing(exitOpacity, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 800)

    };

    useEffect(() => {

        toogleOpacities();

    }, [])

    useEffect(() => {

        if (isSearching.toString().length == 0) {

            setSearch([])
        } else {

            setSearch([]);

            var listToFilter = [];

            songs.forEach(element => {

                if (element.lyrics.includes(isSearching)) {

                    listToFilter = [...listToFilter, element];
                    setSearch(listToFilter);
                }

            });

        }

        console.log(search)

    }, [isSearching])

    const searchRef = useRef();

    return (
        <ScrollView style={{ backgroundColor: colors.black }}>
            <View style={{ width: width, height: height, backgroundColor: colors.black, alignItems: 'center', paddingTop: 40 }}>

                {width > 650 && <Animated.View style={{ width: 50, height: 50, alignItems: 'center', opacity: exitOpacity, justifyContent: 'center', position: 'absolute', top: 50, left: 50 }}>


                    <TouchableOpacity onPress={() => navigation.goBack()}>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                        >

                            <MaterialCommunityIcons name='chevron-left' size={30} color={colors.white} />


                        </LinearGradient>

                    </TouchableOpacity>

                </Animated.View>}

                {width < 650 && <Animated.View style={{ width: 30, height: 30, alignItems: 'center', opacity: exitOpacity, justifyContent: 'center', position: 'absolute', top: 20, left: 20 }}>


                    <TouchableOpacity onPress={() => navigation.goBack()}>

                        <LinearGradient
                            colors={['#FF5733', '#A83279']}
                            style={{ height: 30, width: 30, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                        >

                            <MaterialCommunityIcons name='chevron-left' size={25} color={colors.white} />


                        </LinearGradient>

                    </TouchableOpacity>

                </Animated.View>}

                <Animated.View style={{ width: '80%', height: 50, marginVertical: 10, alignItems: 'center', opacity: searchBarOpacity, marginTop: 30, zIndex: 1 }}>

                    <AppTextInput placeHolderName={"Search here"} onChangeEvent={(text) => setIsSearching(text)} referance={searchRef} onPressProp={() => searchRef.current.focus()} />

                </Animated.View>

                <Animated.View style={{ width: '100%', height: '100%', opacity: textOpacity, alignItems: 'center', justifyContent: 'center' }}>




                    {width < 650 &&

                        <Animated.View style={{ alignItems: 'center', justifyContent: 'center', position: 'absolute', top: 0 }}>
                            <Animated.FlatList

                                data={search}

                                keyExtractor={(item) => item.id.toString()}

                                showsVerticalScrollIndicator={false}

                                renderItem={({ item }) => {


                                    return (<View style={{ width: 300, height: 250, marginHorizontal: 30, marginVertical: 20, overflow: 'hidden', borderRadius: 30 }}>
                                        <TouchableOpacity style={{ width: '100%', height: '100%', padding: 15 }} onPress={() => navigation.navigate("FullSong", item)}>

                                            <Image style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }} source={{ uri: item.image }} />

                                            <AppText text={item.name} style={{
                                                fontSize: 24,
                                                fontWeight: 'bold',
                                                color: colors.white,
                                                textAlign: 'left',
                                                textShadowColor: 'rgba(0, 0, 0, 0.75)',
                                                textShadowOffset: { width: 2, height: 2 },
                                                textShadowRadius: 5,
                                            }} />

                                            <TouchableOpacity onPress={() => navigation.navigate("FullSong", item)}>

                                                <LinearGradient
                                                    colors={['#FF5733', '#A83279']}
                                                    style={{ height: 30, width: 120, marginTop: 10, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                                                >

                                                    <AppText text={"Listen"} style={{ fontSize: 13, color: colors.white }} />


                                                </LinearGradient>

                                            </TouchableOpacity>


                                        </TouchableOpacity>
                                    </View>);



                                }}


                            />
                        </Animated.View>

                    }

                    {width > 650 &&

                        <Animated.View >

                            <Animated.FlatList

                                data={search}

                                numColumns={3}

                                keyExtractor={(item) => item.id.toString()}

                                showsVerticalScrollIndicator={false}

                                renderItem={({ item }) => {


                                    return (<View style={{ width: 320, height: 250, marginHorizontal: 30, marginVertical: 20, overflow: 'hidden', borderRadius: 30 }}>
                                        <TouchableOpacity style={{ width: '100%', height: '100%', padding: 15 }} onPress={() => navigation.navigate("FullSong", item)}>

                                            <Image style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }} source={{ uri: item.image }} />

                                            <AppText text={item.name} style={{
                                                fontSize: 24,
                                                fontWeight: 'bold',
                                                color: colors.white,
                                                textAlign: 'left',
                                                textShadowColor: 'rgba(0, 0, 0, 0.75)',
                                                textShadowOffset: { width: 2, height: 2 },
                                                textShadowRadius: 5,
                                            }} />

                                            <TouchableOpacity onPress={() => navigation.navigate("FullSong", item)}>

                                                <LinearGradient
                                                    colors={['#FF5733', '#A83279']}
                                                    style={{ height: 30, width: 120, marginTop: 10, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                                                >

                                                    <AppText text={"Listen"} style={{ fontSize: 13, color: colors.white }} />


                                                </LinearGradient>

                                            </TouchableOpacity>


                                        </TouchableOpacity>
                                    </View>);



                                }}


                            />
                        </Animated.View>
                    }

                    {search.length == 0 && <View style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>

                        <Lottie animationData={require("../assets/animations/sound.json")} loop={true} style={{ width: 200, height: 200 }} />
                        <AppText text={"Enter the words that you have heard from the chant and it will show you the song here!"} style={{ fontSize: 13, width: width / 1.5, color: colors.white }} />

                    </View>}

                </Animated.View>

            </View>
        </ScrollView>
    );
}

export default DetectorScreen;