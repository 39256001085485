import React, { useEffect, useRef, useState } from 'react';
import { View, Dimensions, Animated, FlatList, Image, TouchableOpacity } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { MaterialCommunityIcons } from '@expo/vector-icons';

import AppText from '../components/AppText';
import colors from '../colors/colors';

const height = window.innerHeight;
const width = window.innerWidth;

const items = [

    { id: 1, title: "LIV 4 - 0 BAR", videoId: "buoMlAshKXQ", image: "https://d3j2s6hdd6a7rg.cloudfront.net/v2/uploads/media/default/0002/06/thumb_105163_default_news_size_5.jpeg" },
    { id: 2, title: "LIV 3 - 3 ACM", videoId: "3ojXHf293M8", image: "https://media.cnn.com/api/v1/images/stellar/prod/150529184248-liverpool-champions-league-trophy.jpg?q=w_2970,h_1845,x_0,y_0,c_fill" },
    { id: 3, title: "LIV 7 - 0 MNU", videoId: "0aVeHpnVDYg", image: "https://images2.minutemediacdn.com/image/upload/c_crop,w_4028,h_2265,x_0,y_147/c_fill,w_720,ar_16:9,f_auto,q_auto,g_auto/images/GettyImages/mmsport/90min_en_international_web/01gtshs1xg3m5js6jr0r.jpg" },
    { id: 4, title: "LIV 2 - 0 TOT", videoId: "zIlwMVkeJ5E", image: "https://static01.nyt.com/images/2019/06/01/sports/01champions19/01champions19-superJumbo.jpg" },
    { id: 5, title: "LIV 11 - 10 CHE", videoId: "DWU4FYgGOaI", image: "https://i.guim.co.uk/img/media/fdacec78207fe09c2529e3b8311bceec7d51cc06/0_10_5472_3283/master/5472.jpg?width=1200&quality=85&auto=format&fit=max&s=d86b9b928f63ed290889144739bd8a8f" },
    { id: 6, title: "LIV 2 - 1 NEW", videoId: "LufIaT7ZqFg", image: "https://e0.365dm.com/23/08/2048x1152/skysports-darwin-nunez-liverpool_6265184.jpg?20230827181819" },
    { id: 7, title: "ORIGIII", videoId: "4rG1sP1rhow", image: "https://external-preview.redd.it/rrzCd9-e49toTMQ7lzaQz7Wzn215cGZlpqC8iKTd6U4.png?format=pjpg&auto=webp&s=2a46c0f9ac104230065f6b57082dc7071b566915" },
    { id: 8, title: "LIV 1 - 0 EVE", videoId: "0iXX_xN05Cg", image: "https://img.bleacherreport.net/img/images/photos/003/780/061/hi-res-46d75050cce2403e3411ce9b37c80fc7_crop_north.jpg?1544599864&w=3072&h=2048" },
    { id: 9, title: "LIV 6 - 5 CHE", videoId: "IK6ky_jbBqI", image: "https://backend.liverpoolfc.com/sites/default/files/styles/xl/public/acquiadam/2022-05/Jordan-Henderson-trophy-lift-gallery-14052022.png?itok=xxKfbr-j" },

]

function LegendaryMomentsScreen({ navigation }) {

    const ListOpacity = useState(new Animated.Value(0))[0];

    const SongsTextOpacity = useState(new Animated.Value(0))[0];

    const exitOpacity = useState(new Animated.Value(0))[0];

    const scrollY = useRef(new Animated.Value(0)).current;

    const toogleOpacities = () => {

        Animated.timing(SongsTextOpacity, {
            toValue: 1,
            duration: 800,
            useNativeDriver: true,
        }).start();

        setTimeout(function () {
            Animated.timing(ListOpacity, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 500)

        setTimeout(function () {
            Animated.timing(exitOpacity, {
                toValue: 1,
                duration: 800,
                useNativeDriver: true,
            }).start();
        }, 800)

    };

    useEffect(() => {

        toogleOpacities();

    }, [])

    return (
        <View style={{ width: '100%', alignItems: 'center', paddingTop: width > 650 ? 20 : 40, backgroundColor: colors.black }}>

            {width > 650 && <Animated.View style={{ width: 50, height: 50, alignItems: 'center', opacity: exitOpacity, justifyContent: 'center', position: 'absolute', top: 50, left: 50 }}>


                <TouchableOpacity onPress={() => navigation.goBack()}>

                    <LinearGradient
                        colors={['#FF5733', '#A83279']}
                        style={{ height: 50, width: 50, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                    >

                        <MaterialCommunityIcons name='chevron-left' size={30} color={colors.white} />


                    </LinearGradient>

                </TouchableOpacity>

            </Animated.View>}

            {width < 650 && <Animated.View style={{ width: 30, height: 30, alignItems: 'center', opacity: exitOpacity, justifyContent: 'center', position: 'absolute', top: 20, left: 20 }}>


                <TouchableOpacity onPress={() => navigation.goBack()}>

                    <LinearGradient
                        colors={['#FF5733', '#A83279']}
                        style={{ height: 30, width: 30, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                    >

                        <MaterialCommunityIcons name='chevron-left' size={25} color={colors.white} />


                    </LinearGradient>

                </TouchableOpacity>

            </Animated.View>}

            <Animated.View style={{ width: '80%', alignItems: 'center', opacity: SongsTextOpacity, justifyContent: 'center' }}>
                <AppText text={"LEGENDARY MOMENTS"} gradientSize={width > 650 ? 100 : 60} gradient={true} />
            </Animated.View>

            {width < 650 &&

                <Animated.View style={{ opacity: ListOpacity }}>
                    <Animated.FlatList

                        data={items}

                        keyExtractor={(item) => item.id.toString()}

                        showsVerticalScrollIndicator={false}

                        onScroll={Animated.event(

                            [{ nativeEvent: { contentOffset: { y: scrollY } } }],
                            { useNativeDriver: true },

                        )}

                        renderItem={({ item, index }) => {
                            const inputRange = [-1, 0, index * 240, 240 * (index + 2)];
                            const scale = scrollY.interpolate({
                                inputRange,
                                outputRange: [1, 1, 1, 0]

                            })

                            return (<View style={{ width: 300, height: 250, marginHorizontal: 30, marginVertical: 20, overflow: 'hidden', borderRadius: 30 }}>
                                <TouchableOpacity style={{ width: '100%', height: '100%' }} onPress={() => navigation.navigate("FullSong", item)}>

                                    <Image style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }} source={{ uri: item.image }} />


                                    <View style={{ width: '100%', position: 'absolute', bottom: 10, alignItems: 'center' }}>
                                        <AppText text={item.title} style={{
                                            fontSize: 24,
                                            fontWeight: 'bold',
                                            color: colors.white,
                                            textAlign: 'left',
                                            textShadowColor: 'rgba(0, 0, 0, 0.75)',
                                            textShadowOffset: { width: 2, height: 2 },
                                            textShadowRadius: 5,
                                        }} />

                                        <TouchableOpacity onPress={() => navigation.navigate("FullVideo", item)}>

                                            <LinearGradient
                                                colors={['#FF5733', '#A83279']}
                                                style={{ height: 30, width: 120, marginTop: 10, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                                            >

                                                <AppText text={"Watch"} style={{ fontSize: 13, color: colors.white }} />


                                            </LinearGradient>

                                        </TouchableOpacity>
                                    </View>


                                </TouchableOpacity>
                            </View>);



                        }}


                    />
                </Animated.View>

            }

            {width > 650 &&

                <Animated.View style={{ opacity: ListOpacity }}>

                    <Animated.FlatList

                        data={items}

                        numColumns={3}

                        keyExtractor={(item) => item.id.toString()}

                        showsVerticalScrollIndicator={false}

                        renderItem={({ item }) => {


                            return (<View style={{ width: 320, height: 250, marginHorizontal: 30, marginVertical: 20, overflow: 'hidden', borderRadius: 30 }}>
                                <TouchableOpacity style={{ width: '100%', height: '100%' }} onPress={() => navigation.navigate("FullSong", item)}>

                                    <Image style={{ width: '100%', height: '100%', position: 'absolute', top: 0, left: 0 }} source={{ uri: item.image }} />

                                    <View style={{ width: '100%', position: 'absolute', bottom: 10, alignItems: 'center' }}>
                                        <AppText text={item.title} style={{
                                            fontSize: 24,
                                            fontWeight: 'bold',
                                            color: colors.white,
                                            textAlign: 'left',
                                            textShadowColor: 'rgba(0, 0, 0, 0.75)',
                                            textShadowOffset: { width: 2, height: 2 },
                                            textShadowRadius: 5,
                                        }} />

                                        <TouchableOpacity onPress={() => navigation.navigate("FullVideo", item)}>

                                            <LinearGradient
                                                colors={['#FF5733', '#A83279']}
                                                style={{ height: 30, width: 120, marginTop: 10, justifyContent: 'center', alignItems: 'center', borderRadius: 25, zIndex: 1 }}
                                            >

                                                <AppText text={"Watch"} style={{ fontSize: 13, color: colors.white }} />


                                            </LinearGradient>

                                        </TouchableOpacity>
                                    </View>

                                </TouchableOpacity>
                            </View>);



                        }}


                    />
                </Animated.View>
            }

        </View>
    );
}

export default LegendaryMomentsScreen;